import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericService } from '../../shared/genric-service/generic.service';
import { Utils } from '../../shared/utils';
import { RulesService } from '../../services/rules.service';
import { map, Observable, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'app-remediation-filters',
  templateUrl: './remediation-filters.component.html',
  styleUrl: './remediation-filters.component.scss'
})
export class RemediationFiltersComponent {
  readonly data = inject<any>(MAT_DIALOG_DATA);
  filtersFrom: FormGroup = this.fb.group({});

  public assetTypeList: any = [];
  public ruleStates: any = [];
  public vtDataForFilter: any;
  public vtData: any = []
  public VTfilter: any = {};
  isDarkMode: boolean = false;

  assetsNameList: any = [];
  isAssetNamesLoading: boolean = false;
  suggestions: any = [];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RemediationFiltersComponent>,
    private genericService: GenericService,
    private utils: Utils,
    public rulesService: RulesService
  ) {
    genericService.isDarkMode.subscribe((res: boolean) => {
      this.isDarkMode = res;
    })

    this.assetTypeList = this.utils.getAssetTypesForRules();
    this.ruleStates = this.rulesService.getRuleStates();
  }

  ngOnInit(): void {
    this.initFiltersForm();
    this.getAllVTS('SERVER,CLOUD_CONFIG');
  }

  initFiltersForm() {
    this.filtersFrom = this.fb.group({
      vulnerability: [''],
      assetType: [''],
      assetName: [''],
      state: [''],
    });
    if (this.data && this.data.ids) {
      this.filtersFrom.patchValue(this.data.ids)
    }
  }

  filteredAssetNameOptions: Observable<any[]> | undefined;
  frequentFilterSearchMap: any = {};

  getAssetNameListForDropdown() {
    this.frequentFilterSearchMap = {};

    if (this.filtersFrom.get('assetType')?.value) {
      this.frequentFilterSearchMap['ASSET_TYPE'] = this.filtersFrom.get('assetType')?.value;
    }

  //  this.filtersFrom.get('assetName')?.valueChanges.pipe(
  //     startWith(''),
  //     switchMap(value => {
     
  //     })
  //  ).subscribe((data: any) => { 
  //    this.suggestions = data;
  //  });
  }

  search(e :any) {
    console.log('search', e)
    
       const filter = {
          startIndex: null,
          count: null,
          groupBy: '',
          searchMap: this.frequentFilterSearchMap,
          excludeMap: {},
          sortBy: 'severity',
          sortOrder: 'desc',
        };
        const api = '/assets/name/search/';
         this.genericService.postRequest(api, filter, true).pipe(
          map((data: any) => {
            if (data.success) {
              this.assetsNameList = data.data;
            }
            
           return this._filterAssetName(e?.query);
          })
        ).subscribe((data: any) => { 
     this.suggestions = data;
   });
  }

  private _filterAssetName(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : '';

    return this.assetsNameList.filter((option: any) =>
      option.name ? option.name.toLowerCase().includes(filterValue) : false
    );
  }

  getAllVTS(assetType: any) {
    this.VTfilter = {
      startIndex: '',
      count: '',
      groupBy: '',
      sortOrder: 'asc',
      sortBy: 'seconizeName',
      excludeMap: {
        SUPPRESS: 'true',
      },
      searchMap: {
        ASSET_TYPE: assetType,
        AUTO: 'true',
      },
    };

    let api = '/drc/vts/search/'
    this.genericService.postRequest(api, this.VTfilter, true).subscribe((data: any) => {
      if (
        data['success'] &&
        data['data'].vulnTemplates.length != 0 &&
        data['data'] != null
      ) {
        if (assetType == 'SERVER,CLOUD_CONFIG') {
          this.vtDataForFilter = data['data'].vulnTemplates;
        } else {
          this.vtData = data['data'].vulnTemplates;
        }
        // this.loaderService.display(false);
        // this.isPageLoading = false
        // this.isTableLoading = false
      } else {
        // this.loaderService.display(false);
        // this.isPageLoading = false
        // this.isTableLoading = false
      }
    });
  }

  resetFilter() {
    this.filtersFrom.reset();
  }
  onApply() {
    let formValue = this.filtersFrom.value;
    this.dialogRef.close({ event: 'applyFilters', data: formValue });
  }
  onClose() {
    this.dialogRef.close({ event: 'close' });
  }

}
