import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { filter } from '../shared/models/filter';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { Utils } from '../shared/utils';
import { GenericService } from '../shared/genric-service/generic.service';
@Injectable({
  providedIn: 'root',
})
export class GroupRulesService {
  constructor(
    public http: HttpClient,
    private utils: Utils,
    private genericService: GenericService
  ) {}
  getAllFixRules(filter: filter) {
    return this.genericService
      .postRequest<any>('/fixes/rules/search/', filter, false)
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }
  getAllFixTemplates(filter: any) {
    return this.genericService
      .postRequest<filter>('/drc/fixts/search/', filter, false)
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }
  getRuleStates() {
    return [
      {
        name: 'Active',
        value: 'ACTIVE',
      },
      {
        name: 'Inactive',
        value: 'INACTIVE',
      },
    ];
  }
  getAllRemediation(body: any) {
    return this.genericService
      .postRequest<any>('/drc/fixts/search/', body, false)
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }
  deleteRule(ruleId: any): Observable<any> {
    return (
      this.genericService
        // .deleteRequest<filter>(environment.url + '/fixes/rules/' + ruleId,
        .deleteRequest(
          '/fixes/rules/' + ruleId
          // { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } }
        )
        .pipe(
          catchError((e: HttpErrorResponse): any => {
            throwError(this.utils.errorHandler(e));
          })
        )
    );
  }
  activateRule(body: any): Observable<any> {
    return this.genericService
      // .postRequest<filter>(
      //   environment.url + '/fixes/rules/activate/',
      //   body,
      //   false
      // )
      .postRequest('/fixes/rules/activate/', body, false)
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }
  deactivateRule(body: any): Observable<any> {
    return this.genericService
      // .postRequest<filter>(environment.url + '/fixes/rules/deactivate/', body)
      .postRequest('/fixes/rules/deactivate/', body, false)
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }
  createFixRules(filter: filter) {
    return this.genericService
      // .postRequest<filter>(environment.url + '/fixes/rules/', filter)
      .postRequest('/fixes/rules/', filter)
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }
}
