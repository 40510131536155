import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../shared/utils';
import { AssetsService } from '../../services/assets.service';
import { GenericService } from '../../shared/genric-service/generic.service';

@Component({
  selector: 'app-risk-filters',
  templateUrl: './risk-filters.component.html',
  styleUrl: './risk-filters.component.scss'
})
export class RiskFiltersComponent {
  readonly data = inject<any>(MAT_DIALOG_DATA);
  filtersForm: FormGroup = this.fb.group({});
  isDarkMode: boolean = false;
  stateList: any = [
    { name: 'InActive', value: 'INACTIVE' },
    { name: 'Active', value: 'ACTIVE' },
    { name: 'Resolved', value: 'RESOLVED' },
    { name: 'Treated', value: 'TREATED' },
    { name: 'Rejected', value: 'REJECTED' }
  ];
  treatmentList: any = [
    // { name: 'Untreated', value: 'UNTREATED' },
    { name: 'Avoid', value: 'AVOID' },
    { name: 'Mitigate', value: 'MITIGATE' },
    { name: 'Transfer', value: 'TRANSFER' },
    { name: 'Accept', value: 'ACCEPT' },
    { name: 'Untreated', value: 'UNTREATED' },
    { name: 'Not Applicable', value: 'NOT_APPLICABLE' },
  ]
  isUsersLoading: boolean = false;
  managedByUserList: any[] = [];
  userIdMap: any = [];
  userList: any[] = [];
  categoryList:any[] = [];
  isLnT_user: boolean = false;
  assetTypeList: any = [];
  assetList: any[] = [];
  assetSubTypesList: any[] = [];
  assetNameList: any[] = [];
  groupList: any[] = [];
  selectedAgeRelation = '';

  ageRelation(symbol: any) {
    return (this.selectedAgeRelation = symbol);
  }
  constructor(public dialogRef: MatDialogRef<RiskFiltersComponent>,
    private fb: FormBuilder,
    private genericService: GenericService,
    public utils: Utils,
    private assetService:AssetsService
  ){
    this.genericService.isDarkMode.subscribe((res: boolean) => {
      this.isDarkMode = res;
    })
  }
  ngOnInit(){
    this.initForm();
    this.getAllRiskManagerUsers();
    this.getAllUsers();
    this.isLnT_user = localStorage.getItem('loggedInUserName')!.includes('larsentoubro.com')
    let getCategoryListFn = this.utils.getCategoryList(true)

    if (this.isLnT_user) {
      this.categoryList = getCategoryListFn.category.filter((item: { value: string; }) =>
        item.value !== 'BUILDING' && item.value !== 'DATA_CENTER'
        && item.value !== 'USER' && item.value !== 'INFRASTRUCTURE'
        && item.value !== 'EXTRAS' && item.value !== 'CLOUD');
    } else {
      this.categoryList = getCategoryListFn.category;
    }
    this.assetList = getCategoryListFn.assetTypeList;
    this.assetSubTypesList = this.assetService.getAllAssetSubTypes(true);
    this.assetTypeList = this.utils.updateAssetDropdown(false, this.assetList, true);
  }
  initForm(){
    this.filtersForm = this.fb.group({
      selectedState: [''],
      selectedTreatment: [''],
      selectedManagedBy: [''],
      selectedAssignee: [''],
      selectedCategory: [''],
      selectedType: [''],
      selectedSubType: [''],
      selectedOverDue: [''],
      selectedAge: [''],
    });
    if(this.data && this.data.ids){
      this.filtersForm.controls['selectedState'].patchValue(this.data.ids['STATE'])
      this.filtersForm.controls['selectedTreatment'].patchValue(this.data.ids['TREATMENT'])
      this.filtersForm.controls['selectedManagedBy'].patchValue(this.data.ids['MANAGED_BY'])
      this.filtersForm.controls['selectedAssignee'].patchValue(this.data.ids['ASSIGNEE'])
      this.filtersForm.controls['selectedCategory'].patchValue(this.data.ids['CATEGORY'])
      this.filtersForm.controls['selectedType'].patchValue(this.data.ids['ASSET_TYPE'])
      this.filtersForm.controls['selectedSubType'].patchValue(this.data.ids['SUBTYPE'])
      this.filtersForm.controls['selectedOverDue'].patchValue(this.data.ids['IS_OVERDUE'])
      if(this.data.ids['AGE']){
        this.filtersForm.controls['selectedAge'].patchValue(this.extractNumber(this.data.ids['AGE']));
        this.selectedAgeRelation = this.data.ids.selectedAgeRelation ? this.data.ids.selectedAgeRelation : ''
      }
    }
  }
  extractNumber(str: string): number | null {
    const match = str.match(/([>=<])(\d+)/);
    if (match) {
      return parseInt(match[2], 10);
    }
    return null;
  }
  getAllRiskManagerUsers() {
    if (this.managedByUserList.length <= 0) {
      this.isUsersLoading = true
      let userFilter = {
        startIndex: '',
        count: '',
        groupBy: '',
        sortOrder: '',
        sortBy: '',
        searchMap: { IS_DRC_USER: true },
        excludeMap: {},
      };
      let api = '/identity/users/search/'
      this.genericService.postRequest(api, userFilter,false).subscribe((data: any) => {
        this.isUsersLoading = false
        if (data.success && data.data != null && data.data.users.length > 0) {
          for (var user of data.data.users) {
            if (user.riskRole === 'RISK_MGR') {
              // this.managedByUserList.push(user.firstName + ' ' + user.lastName);
              this.managedByUserList.push({
                label: user.firstName + ' ' + user.lastName,
                value: user.userName,
              });
            }
            this.userIdMap[user.userName] = user.id;
          }
        }
      });
    }
  }
  getAllUsers() {
    if (this.userList.length <= 0) {
      this.isUsersLoading = true
      let userFilter = {
        startIndex: '',
        count: '',
        groupBy: '',
        sortOrder: '',
        sortBy: '',
        searchMap: { IS_DRC_USER: true },
        excludeMap: {},
      };
      let api = '/identity/users/search/'
      this.genericService.postRequest(api, userFilter,false).subscribe((data: any) => {
        this.isUsersLoading = false
        if (data.success && data.data != null && data.data.users.length > 0) {
          for (var user of data.data.users) {
            if (user.riskRole === 'RISK_OWNER') {
              // this.userList.push(user.firstName + ' ' + user.lastName);
              this.userList.push({
                label: user.firstName + ' ' + user.lastName,
                value: user.userName,
              });
            }
            // this.userList.push(user.userName);
            this.userIdMap[user.userName] = user.id;
          }
        }
      });
    }
  }

  getAssetSubTypes(val: any, category?: any) {
    this.assetNameList = [];
    this.groupList = [];
    this.assetSubTypesList = []

    if (val === 'SERVER') {
      if (category == 'HARDWARE' || category == 'SOFTWARE') {
        this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category))
      } else {
        this.assetSubTypesList = this.assetService.getServerTypes();
        if (true) {
          this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val))
        }
      }

    } else if (val === 'END_POINT') {
      if (category == 'HARDWARE') {
        this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category))
      } else {
        this.assetSubTypesList = this.assetService.getEndpointTypes();
        if (true) {
          this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val))
        }
      }

    } else if (val === 'MOBILE_APP') {
      this.assetSubTypesList = this.assetService.getMobileTypes();

    } else if (val === 'CLOUD_CONFIG') {
      this.assetSubTypesList = this.assetService.getCloudTypes();

    } else if (val === 'DB') {
      this.assetSubTypesList = this.assetService.getDatabaseTypes();

    } else if (val === 'NETWORK') {

      if (category == 'HARDWARE') {
        this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category))
      } else {
        this.assetSubTypesList = this.assetService.getNetworkTypes();
        if (true) {
          this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val))
        }
      }

    } else if (val === 'K8S') {
      this.assetSubTypesList = this.assetService.getKubernetesTypes();

    } else if (val === 'WEBSITE') {
      this.assetSubTypesList = this.assetService.getWebsiteTypes();

    } else if (val === 'NA') {
      this.assetSubTypesList = this.utils.getSubTypesForAny();

    } else if (val === 'WAF') {
      this.assetSubTypesList = this.utils.getSubTypesForWAF();

    } else if (val === 'STORAGE') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'PHYSICAL') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'SOFTWARE') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'USER') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'DC_EQUIPMENT') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'DESKTOP') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'LAPTOP') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'SAN_STORAGE') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'SAN_SWITCH') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'OTHER') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'APPLICATION') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'DOCUMENT') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'SSL_CERTIFICATE') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'DIGITAL_DATA') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'PEOPLE') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'SERVICE_PROVIDERS') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));

    } else if (val === 'SERVICES') {
      this.assetSubTypesList = this.assetSubTypesList.concat(this.assetService.getSubTypes(val, category));
    }
  }
  onApply(){
    this.dialogRef.close({ event: 'applyFilters', data: {...this.filtersForm.value,selectedAgeRelation:this.selectedAgeRelation} });
  }
  resetFilter(){
    this.filtersForm.reset();
    this.selectedAgeRelation = '';
  }
  onClose(){
    this.dialogRef.close({ event: 'close' });
  }
}
