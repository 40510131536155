import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericService } from '../../shared/genric-service/generic.service';
import { formatDate } from '@angular/common';
import { jwtDecode, JwtPayload } from 'jwt-decode';
@Component({
  selector: 'app-policy-filters-popup',
  templateUrl: './policy-filters-popup.component.html',
  styleUrl: './policy-filters-popup.component.scss'
})
export class PolicyFiltersPopupComponent {
  readonly data = inject<any>(MAT_DIALOG_DATA);
  filtersFrom: FormGroup = this.fb.group({});
  isCompliancesLoading: boolean = false;
  isAuditsLoading: boolean = false;
  auditListbyCompType: any[] = [];
  isUsersLoading: boolean = false;
  usersList: any[] = [];
  stateList: any = [
    'INPROGRESS', 'VALID', 'INVALID', 'UNSUPPORTED', 'FAILED'
  ]
  isBUsLoading: boolean = false;
  buList: any[] = [];
  isPageLoading: boolean = false;
  decodedToken: any;
  isDarkMode: boolean = false;
  minDate = new Date();
  isAdminBU: boolean = false;
  singleBu: string = 'false';





  creatorsList: any[] = [];

  policyFileStatusList: any = [
    { name: 'Unknown', value: 'UNKNOWN' },
    { name: 'Review Pending', value: 'REVIEW_PENDING' },
    { name: 'Approval Pending', value: 'APPROVAL_PENDING' },
    { name: 'Revision Request', value: 'REVISION_REQUEST' },
    { name: 'Approved', value: 'APPROVED' },
  ]

  booleanList: any = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ]
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PolicyFiltersPopupComponent>,
    private genericService: GenericService
  ) {
    this.decodedToken = jwtDecode<JwtPayload>(localStorage.getItem('token')!);
    if (localStorage.getItem('singleBu')?.toString()) {
      this.singleBu = localStorage.getItem('singleBu')?.toString()!
    }
    if (this.decodedToken.isAdminDomain && this.singleBu == "false") {
      this.isAdminBU = true
    } else {
      this.isAdminBU = false
    }
    genericService.isDarkMode.subscribe((res: boolean) => {
      this.isDarkMode = res;
    })
  }

  ngOnInit(): void {
    if (this.data.filters) {
      this.creatorsList = this.data.filters.creator;
      this.buList = this.data.filters.bu;
    }
    this.initFiltersForm();
  }

  initFiltersForm() {
    this.filtersFrom = this.fb.group({
      creator: [''],
      startDate: [''],
      endDate: [''],
      version: [''],
      status: [''],
      isDraftPending: [''],
      doesExceptionExist: [''],
      changeRequestExist: [''],
      buId: [''],
    });
    if (this.data && this.data.ids) {
      this.filtersFrom.patchValue(this.data.ids)
    }
  }

  applyCreatedDate() {
    if (this.filtersFrom.controls['startDate'].value && this.filtersFrom.controls['endDate'].value) {
      this.filtersFrom.controls['startDate'].patchValue(formatDate(this.filtersFrom.controls['startDate'].value && this.filtersFrom.controls['startDate'].value, 'yyyy-MM-dd', 'en-US'));
      this.filtersFrom.controls['endDate'].patchValue(formatDate(this.filtersFrom.controls['endDate'].value && this.filtersFrom.controls['endDate'].value, 'yyyy-MM-dd', 'en-US'));
    }
  }

  resetFilter() {
    this.filtersFrom.reset();
  }
  onApply() {
    let currentCreator;
    let currentBu;
    if (this.filtersFrom.controls['creator'].value) {
      let creatorName = this.creatorsList.find(el => el.userName === this.filtersFrom.controls['creator'].value);
      currentCreator = creatorName.firstName + ' ' + creatorName.lastName;
    }
    if (this.filtersFrom.controls['buId'].value) {
      currentBu = this.buList.find(el => el.id === this.filtersFrom.controls['buId'].value).name;
    }
    let formValue = this.filtersFrom.value;
    formValue['currentCreator'] = currentCreator;
    formValue['currenBu'] = currentBu
    this.dialogRef.close({ event: 'applyFilters', data: formValue });
  }
  onClose() {
    this.dialogRef.close({ event: 'close' });
  }

  getCreators() {
    if (this.creatorsList?.length <= 0) {

      let searchMap: any = {};
      searchMap['USER_ROLE'] = 'POLICY_OWNER';
      searchMap['LITE'] = true;
      searchMap['STATUS'] = 'ACTIVE';

      var body = {
        searchMap: searchMap,
        excludeMap: {},
        startIndex: '',
        count: '',
        sortBy: null,
        sortOrder: null,
        groupBy: null,
      };

      let api = '/identity/users/search/'
      this.genericService.postRequest(api, body, true).subscribe((data: any) => {
        if (data != null && data.success && data.data.users.length > 0) {
          this.creatorsList = data.data.users;
        }
      })
    }

  }

  validateNo(e: any): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }
  

  getAllBUs() {
    if (this.buList.length <= 0) {
      this.isPageLoading = true

      this.isBUsLoading = true

      let filter: any = {
        startIndex: '',
        count: '',
        sortBy: '',
        sortOrder: '',
        searchMap: {},
        excludeMap: {}
      }

      let api = '/bu/search/'
      this.genericService.postRequest(api, filter, true).subscribe((data: any) => {
        this.isBUsLoading = false
        if (data.success && data.data != null && data.data.businessUnits.length > 0) {
          this.buList = data.data.businessUnits
        }
      });
    }
  }
}
