<div class="login-wrapper">
  <div class="sections row">
    <div class="left-section">
      <div class="seconize-logo">
        <img
          style="width: 150px"
          src="../../../assets/images/login/seconize-logo.png"
          alt=""
        />
      </div>
      <div class="derisk-logo">
        <img
          style="width: 150px"
          src="../../../assets/images/login/logo-new.png"
          alt=""
        />
        <div class="welcome-text">
          <p>Set a new password</p>
        </div>
      </div>

      <div>
        <div class="card-body">
          <section class="forgot-wrapper" *ngIf="!isSuccess">
            <p class="typo">
              Create a new password.
              {{
                isActivation
                  ? "Ensure it is strong for better security."
                  : "Ensure it differs from previous ones for security"
              }}
            </p>
            <form [formGroup]="newPasswordForm">
              <div class="seconize-input-filed">
                <div class="flex flex-column gap-2" *ngIf="expired">
                  <label class="login-label">Old Password *</label>
                  <p-password
                    formControlName="oldPassword"
                    [feedback]="false"
                    [toggleMask]="true"
                  >
                  </p-password>
                  <small
                    class="error-text"
                    *ngIf="
                      newPasswordForm.controls['oldPassword'] &&
                      newPasswordForm.controls['oldPassword'].errors &&
                      newPasswordForm.controls['oldPassword'].errors['required']
                    "
                  >
                    Old password is required
                  </small>
                  <small
                    class="error-text"
                    *ngIf="
                      newPasswordForm.controls['oldPassword'] &&
                      newPasswordForm.controls['oldPassword'].errors &&
                      newPasswordForm.controls['oldPassword'].errors['pattern']
                    "
                  >
                    Old password does not match the requirements.
                  </small>
                </div>
                <div class="flex flex-column gap-2">
                  <label class="login-label"
                    >New Password *
                    <i
                      class="pi pi-info-circle sze-gray-text"
                      [pTooltip]="tooltipContent"
                      tooltipPosition="bottom"
                    ></i>

                    <ng-template #tooltipContent>
                      <div>
                        <p>Password Must Contain Minimum 12 characters,</p>
                        <p>Maximum 24 characters,</p>
                        <p>One Lowercase letter,</p>
                        <p>One Uppercase letter,</p>
                        <p>One Numeric digit,</p>
                        <p>One Special Character</p>
                        <p>Shall not contain first name/last name.</p>
                      </div>
                    </ng-template>
                  </label>
                  <!-- <span class="row w-100"> -->
                  <p-password
                    formControlName="newPassword"
                    [feedback]="false"
                    [toggleMask]="true"
                  >
                  </p-password>
                  <!-- </span> -->

                  <small
                    class="error-text"
                    *ngIf="
                      newPasswordForm.controls['newPassword'] &&
                      newPasswordForm.controls['newPassword'].errors &&
                      newPasswordForm.controls['newPassword'].errors['required']
                    "
                  >
                    Password is required
                  </small>
                  <small
                    class="error-text"
                    *ngIf="
                      newPasswordForm.controls['newPassword'] &&
                      newPasswordForm.controls['newPassword'].errors &&
                      newPasswordForm.controls['newPassword'].errors['pattern']
                    "
                  >
                    Password does not match the requirements.
                  </small>
                </div>
                <div class="flex flex-column gap-2 mb-24px">
                  <label class="login-label">Confirm Password *</label>
                  <p-password
                    formControlName="confirmNewPassword"
                    [feedback]="false"
                    [toggleMask]="true"
                  >
                  </p-password>
                  <small
                    class="error-text"
                    style="font-size: 12px"
                    *ngIf="
                      newPasswordForm.errors &&
                      newPasswordForm.errors['mismatch']
                    "
                  >
                    Passwords don’t match.
                  </small>
                </div>
              </div>

              <p-button
                label="Submit"
                pRipple
                label="Update Password"
                [disabled]="newPasswordForm.invalid"
                (click)="changePassword()"
              />
            </form>
          </section>
          <section class="forgot-wrapper middle-container" *ngIf="isSuccess">
            <h2 class="password-heading">Password Updated Successfully</h2>
            <p class="typo" style="margin-bottom: 16px">
              Redirecting to the login page, please wait a moment.
            </p>
            <p class="typo">
              If the page doesn’t automatically redirect, please
              <span class="link" (click)="navigateToLogin()">click here</span>
            </p>
          </section>
        </div>
      </div>
    </div>

    <div class="right-section">
      <img
        src="../../../assets/images/login/login.svg"
        alt="placeholder-charts"
        class="placeholder-charts"
      />
      <div class="image-text">
        Turbo charge your GRC operations with AI powered Seconize DeRisk Centre.
        Automate Compliance Audits, Risk and Control Assessments, Manage
        Vulnerabilities and Third Party Risks
      </div>
    </div>
  </div>
</div>
