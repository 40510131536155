import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericService } from '../../shared/genric-service/generic.service';
import { GroupRulesService } from '../../services/group-rules.service';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-group-rules-filters',
  templateUrl: './group-rules-filters.component.html',
  styleUrl: './group-rules-filters.component.scss'
})
export class GroupRulesFiltersComponent {
  readonly data = inject<any>(MAT_DIALOG_DATA);
  filtersFrom: FormGroup = this.fb.group({});
 
  decodedToken: any;
  isDarkMode: boolean = false;

  public remediationList: any = [];
  public vtDataForFilter: any;
  public vtData: any = []
  public VTfilter: any = {};
  public assetTypeList: any = [];
  public assetGroupList: any = [];
  public ruleStates: any = [];
  public groupList: any = [];
  isAssetGroupsLoading: boolean = false;
  isPageLoading: boolean = false;
  isTableLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GroupRulesFiltersComponent>,
    private genericService:GenericService,
    private utils: Utils,
    public groupRulesService: GroupRulesService,

  ) {
    genericService.isDarkMode.subscribe((res: boolean) => {
      this.isDarkMode = res;
    })

    this.assetTypeList = this.utils.getAssetTypesForRules();
    this.ruleStates = this.groupRulesService.getRuleStates();
  }

  ngOnInit(): void {
    this.initFiltersForm();
    this.getAllRems();
    this.getAllVTS('SERVER,CLOUD_CONFIG');
    this.getAllAssetGroups();
  }

  initFiltersForm() {
    this.filtersFrom = this.fb.group({
      remediation: [''],
      vulnerability: [''],
      assetType: [''],
      assetGroup: [''],
      state: [''],
    });
    if(this.data && this.data.ids){
      this.filtersFrom.patchValue(this.data.ids)
    }
  }

  getAllRems() {
    let body = {};
    this.groupRulesService.getAllRemediation(body).subscribe((data) => {
      if (
        data.success &&
        data.data != null &&
        data.data.fixTemplates.length > 0
      ) {
        for (var fix of data.data.fixTemplates) {
          this.remediationList.push(fix.name);
        }
      }
    });
  }

  frequentFilterSearchMap: any = {};
  getAllAssetGroups() {
    this.assetGroupList.length = 0;
    this.frequentFilterSearchMap = {};
    this.isAssetGroupsLoading = true;
    if ( this.filtersFrom.controls['assetType'].value) {
      this.frequentFilterSearchMap['G_ASSET_TYPE'] =
      this.filtersFrom.controls['assetType'].value;
    }
    var groupFilter = {
      startIndex: '',
      count: '',
      groupBy: '',
      sortOrder: '',
      sortBy: '',
      searchMap: this.frequentFilterSearchMap,
      excludeMap: {
        TYPE: 'MULTI',
      },
    };

    if (!groupFilter) {
      return
    }

    let api = '/assetgroups/search/'
    this.genericService.postRequest(api, groupFilter, true).subscribe((data: any) => {
      this.isAssetGroupsLoading = false;
      if (
        data.success &&
        data.data != null &&
        data.data.assetGroups.length > 0
      ) {
        this.groupList = data.data.assetGroups;
        for (var group of data.data.assetGroups) {
          this.assetGroupList.push(group.name);
        }
      }
    });
  }

  // get All Vulnerability templates
  getAllVTS(assetType: any) {
    this.isPageLoading = false
        this.isTableLoading = false
    this.VTfilter = {
      startIndex: '',
      count: '',
      groupBy: '',
      sortOrder: 'asc',
      sortBy: 'seconizeName',
      excludeMap: {
        SUPPRESS: 'true',
      },
      searchMap: {
        ASSET_TYPE: assetType,
        AUTO: 'true',
      },
    };

    let api = '/drc/vts/search/'
    this.genericService.postRequest(api, this.VTfilter, true).subscribe((data: any) => {
      if (
        data['success'] &&
        data['data'].vulnTemplates.length != 0 &&
        data['data'] != null
      ) {
        if (assetType == 'SERVER,CLOUD_CONFIG') {
          this.vtDataForFilter = data['data'].vulnTemplates;
        } else {
          this.vtData = data['data'].vulnTemplates;
        }
        // this.loaderService.display(false);
        // this.isPageLoading = false
        // this.isTableLoading = false
      } else {
        // this.loaderService.display(false);
        // this.isPageLoading = false
        // this.isTableLoading = false
      }
    });
  }

  resetFilter() {
    this.filtersFrom.reset();
  }
  onApply() {
    let formValue = this.filtersFrom.value;
    this.dialogRef.close({ event: 'applyFilters', data:formValue });
  }
  onClose() {
    this.dialogRef.close({ event: 'close' });
  }

}
