import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GenericService } from '../../shared/genric-service/generic.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { Dialog } from 'primeng/dialog';
import { BOLD_BUTTON, EditorConfig, ITALIC_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_FULL_BUTTON, JUSTIFY_LEFT_BUTTON, JUSTIFY_RIGHT_BUTTON, ORDERED_LIST_BUTTON, ST_BUTTONS, UNDERLINE_BUTTON, UNORDERED_LIST_BUTTON } from 'ngx-simple-text-editor';

@Component({
  selector: 'app-policy-review-approve',
  templateUrl: './policy-review-approve.component.html',
  styleUrl: './policy-review-approve.component.scss',
})
export class PolicyReviewApproveComponent implements OnInit {
  @ViewChild('mySection') mainView!: ElementRef;
  @ViewChild('requestChangesDiv') requestChangesDiv!: any
  canApprovePolicy: boolean = false;
  logo: any = '';
  isPageLoading: boolean = false;
  selectedPolicy: any;
  selectedBuId: any;
  isDarkMode: boolean = false;
  selectedPolicyId: string = '';
  selectedBu: any;
  draftFiles_dataCollection: any = [];
  draft_files_index: number = 1;
  draft_files_totalCount: any = 0;
  draft_files_totalPages: number = 0;
  draft_files_pageSize: any = 10;
  draftFilesSearchMap: any = {};
  draft_files_itemCount: number = 0;
  isRequestMoreChanges: boolean = false;
  changeRequested: string = '';
  latestDraftFile: any;
  isViewHtml = false;
  isViewPdf = false;
  isViewImg = false;
  fileName!: string;
  file_location: string = '';

  visible: boolean = false;
  policyRole: 'POLICY_APPROVER' | 'POLICY_OWNER' | 'NONE' = 'NONE';

  policiesDropdownLists: any = [];

  approvelPolicyLists: any = [];
  reviewelPolicyLists: any = [];
  content: string = '';
  config: EditorConfig = {
    placeholder: 'Type something...',
    buttons: [BOLD_BUTTON,
      ITALIC_BUTTON,
      UNDERLINE_BUTTON,
      JUSTIFY_LEFT_BUTTON,
      JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON,
      JUSTIFY_FULL_BUTTON,
      ORDERED_LIST_BUTTON,
      UNORDERED_LIST_BUTTON
    ],
  };

  userPermissions!: { role: string, permission: 'MANAGE' | 'MODIFY' | 'VIEW' | 'NONE' } | any;

  constructor(
    private readonly genericService: GenericService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService
  ) {
    this.genericService.setShowHeader(false);
    this.genericService.setShowInnerHeader(false);
    genericService.isDarkMode.subscribe((res: boolean) => {
      this.isDarkMode = res;
    });
    const array = this.router.url.split('/');
    const lastsegment = array[array.length - 2];
    if (lastsegment && lastsegment === 'policies-approve') {
      this.canApprovePolicy = true;
    }

    const permissions = this.genericService.getModulePermission('POLICY');
    if (permissions) {
      this.userPermissions = permissions;
    }

  }

  ngOnInit(): void {
    this.selectedPolicyId = this.route.snapshot.paramMap.get('id') || '';
    this.getLogo();
    this.getPolicyDetail();

    const userObjData = JSON.parse(localStorage.getItem('userData')!);
    if (userObjData && userObjData.policyRole) {
      this.policyRole = userObjData.policyRole;
      if (this.policyRole === 'POLICY_APPROVER') {
        this.getApproverPolicyList();
      }
    }
  }

  getApproverPolicyList() {
    let payload = {
      startIndex: 1,
      count: '15',
      groupBy: '',
      searchMap: {
        DRAFT_STATUS: 'REVIEW_PENDING, APPROVAL_PENDING',
      },
      excludeMap: {},
    };

    this.genericService
      .postRequest(`/policies/search/`, payload, true)
      .subscribe((data) => {
        if (data && data.success && data.data) {
          if (data.data.policy && data.data.policy.length > 0) {
            const userObjData = JSON.parse(localStorage.getItem('userData')!);

            this.approvelPolicyLists = data.data.policy.filter(
              (policy: any) => {
                return (
                  policy.draftStatus === 'APPROVAL_PENDING' &&
                  userObjData.userName === policy.l2Approver
                );
              }
            );

            this.reviewelPolicyLists = data.data.policy.filter(
              (policy: any) => {
                return (
                  policy.draftStatus === 'REVIEW_PENDING' &&
                  userObjData.userName === policy.l1Approver
                );
              }
            );

            if (this.canApprovePolicy) {
              this.policiesDropdownLists = this.approvelPolicyLists;
            } else {
              this.policiesDropdownLists = this.reviewelPolicyLists;
            }
          }
        }
      });
  }

  onPolicyChange() {
    this.getPolicyDetail();
  }

  showDialogMaximized(dialog: Dialog) {
    dialog.maximize();
  }

  onHideDialog(val: any) {
    if (!val.maximized) {
      this.visible = false;
    }
  }

  noActionFlag: boolean = false;
  draftVersion: any;

  private getPolicyDetail() {
    this.isPageLoading = true;
    this.genericService
      .getRequest(`/policies/${this.selectedPolicyId}`)
      .subscribe(
        (data: any) => {
          if (data && data.success && data.data) {
            this.selectedPolicy = data.data;
            this.selectedBuId = data.data.buId;
            this.changeRequested = data.data?.changeSummary || '';
            if (data.data.draftStatus === 'APPROVED') {
              this.noActionFlag = true;
            }
            this.getAllBUs();
            this.getDraftFiles();
          }
          this.isPageLoading = false;
        },
        (err) => {
          this.isPageLoading = false;
        }
      );
  }

  async viewFile(element: any) {
    const all_extensions: any = [
      'xlsx',
      'xls',
      'pptx',
      'ppt',
      'csv',
      'docx',
      'doc',
      'jfif',
      'gif',
      'jpg',
      'jpeg',
      'png',
      'pdf',
      'html',
      'eml',
      'msg',
    ];

    let lowercaseExtension: any = element.rawFileName
      ?.split('.')
    [element.rawFileName?.split('.')?.length - 1]?.toLowerCase();

    // let ms_docs: any = ['.xlsx', '.xls', '.pptx', '.ppt', '.docx', '.doc']
    let imgs: any = ['.png', '.jpg', '.jpeg', '.jfif', '.gif'];

    // To Test Html view
    // this.fileName = 'dwsample1-html.html';
    // this.file_location = 'COMPLIANCE_EVIDENCE/MANUAL/amal@amaltest.com/2024-08-26T05:07:30.537Z/raw/dwsample1-html.html'
    // this.isViewHtml = true;

    // To Test PDf view
    // this.fileName = 'Get_Started_With_Smallpdf.pdf';
    // this.file_location = 'COMPLIANCE_EVIDENCE/MANUAL/amal@amaltest.com/2024-09-04T04:02:38.913Z/raw/Get_Started_With_Smallpdf.pdf'
    // this.isViewPdf = true;

    if (
      element.fileStatus == 'INVALID' ||
      !all_extensions.includes(lowercaseExtension)
    ) {
      return;
    } else if (element.rawFileName?.toLowerCase()?.endsWith('.html')) {
      this.fileName = element.rawFileName;
      this.file_location = element.stgBlobLocation;
      this.isViewHtml = true;
    } else if (
      imgs.includes(
        element.rawFileName
          ?.toLowerCase()
          ?.substring(element.rawFileName?.lastIndexOf('.'))
      )
    ) {
      this.fileName = element.rawFileName;
      this.file_location = element.stgBlobLocation;
      this.isViewImg = true;
    } else if (element.rawFileName?.toLowerCase()?.endsWith('.pdf')) {
      this.fileName = element.rawFileName;
      this.file_location = element.stgBlobLocation;
      this.isViewPdf = true;
    } else if (element.fileStatus != 'INPROGRESS') {
      if (element.rawFileName?.toLowerCase()?.endsWith('.csv')) {
        this.fileName = element.rawFileName;
        this.file_location = element.uploadLocation;
        this.isViewHtml = true;
      } else {
        this.fileName = element.rawFileName;
        this.file_location = element.uploadLocation;
        this.isViewPdf = true;
      }
    }
  }

  getAllBUs() {
    let filter: any = {
      startIndex: '',
      count: '',
      sortBy: '',
      sortOrder: '',
      searchMap: {},
      excludeMap: {},
    };

    let api = '/bu/search/';
    this.genericService.postRequest(api, filter, true).subscribe((data: any) => {
      if (
        data.success &&
        data.data != null &&
        data.data.businessUnits.length > 0
      ) {
        // this.selectedBu = data.data.businessUnits[0];
        this.selectedBu = data.data.businessUnits.find((el: any) => {
          return el.id === this.selectedPolicy.buId;
        });
      }
    });
  }

  getDraftFiles(id?: any) {
    if (id) {
      this.draftFilesSearchMap.DRAFT_FILE_ID = id;
    }

    this.draftFilesSearchMap.POLICY_ID = this.selectedPolicyId;

    this.draft_files_itemCount =
      this.draft_files_pageSize.value * this.draft_files_index;

    let filter: any = {
      startIndex: this.draft_files_index,
      count: this.draft_files_pageSize.value,
      groupBy: '',
      searchMap: this.draftFilesSearchMap,

      excludeMap: {},
    };

    let api = '/policies/draftfile/search/';

    this.genericService.postRequest(api, filter, true).subscribe((res: any) => {
      if (res.success && res.data.policyDraftFiles.length > 0) {
        this.draftFiles_dataCollection = res.data.policyDraftFiles;

        if (this.draft_files_index == 1) {
          this.draft_files_totalCount = res.data.totalCount;
          this.draft_files_totalPages = Math.ceil(
            this.draft_files_totalCount / this.draft_files_pageSize
          );
        }

        if (
          this.draftFiles_dataCollection &&
          this.draftFiles_dataCollection.length > 0
        ) {
          this.latestDraftFile = this.draftFiles_dataCollection.reduce(
            (a: any, b: any) => {
              return new Date(a.createdTimestamp) > new Date(b.createdTimestamp)
                ? a
                : b;
            }
          );

          const draftStatus = this.draftFiles_dataCollection.filter((f: any) => { return f.status === this.selectedPolicy.draftStatus }).reduce(
            (a: any, b: any) => {
              return new Date(a.createdTimestamp) > new Date(b.createdTimestamp)
                ? a
                : b;
            }
          );

          if (draftStatus) {
            this.draftVersion = draftStatus.draftVersion;
          }
        }

        if (
          this.selectedPolicy &&
          this.selectedPolicy.fileId &&
          this.selectedPolicy.stgBlobLocation
        ) {
          this.viewFile(this.selectedPolicy);
        } else {
          if (
            this.draftFiles_dataCollection &&
            this.draftFiles_dataCollection.length > 0
          ) {
            let checkFileStatus: 'APPROVAL_PENDING' | 'REVIEW_PENDING' = this
              .canApprovePolicy
              ? 'APPROVAL_PENDING'
              : 'REVIEW_PENDING';
            const d = this.draftFiles_dataCollection
              .filter((file: any) => {
                return file.status === checkFileStatus;
              })
              .reduce((a: any, b: any) => {
                return new Date(a.createdTimestamp) >
                  new Date(b.createdTimestamp)
                  ? a
                  : b;
              });

            if (d) {
              this.selectedPolicy['fileId'] = d.fileId;
              this.selectedPolicy['stgBlobLocation'] = d.stgBlobLocation;
              this.selectedPolicy['rawFileName'] = d.rawFileName;
              this.viewFile(this.selectedPolicy);
            }
          }
        }
      }
    });
  }

  getLogo() {
    if (
      !localStorage.getItem('Logo') &&
      localStorage.getItem('Logo') !== 'null'
    ) {
      let api = '/identity/users/license';
      this.genericService.getRequest(api, false).subscribe((data: any) => {
        if (data.success) {
          localStorage.setItem('Logo', JSON.stringify(data.data.logo));
          this.logo = JSON.parse(localStorage.getItem('Logo') || '');
        }
      });
    } else {
      this.logo = JSON.parse(localStorage.getItem('Logo') || '');
    }
  }

  async downloadFile() {
    let fileId: any = this.selectedPolicy.fileId;
    let file_location: any = this.selectedPolicy.stgBlobLocation;
    let rawFileName: any = this.selectedPolicy.rawFileName;
    if (fileId && file_location) {
      if (localStorage.getItem('minioAuditConnector')) {
        this.genericService.downloadFileFromMinio(file_location).subscribe({
          next: (blob: Blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = rawFileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.snackbarService.success('Downloaded successfully', '', 3000);
            // this.spinner.hide();
          },
          error: (error) => {
            // console.error('There was an error!', error);
            this.snackbarService.error('There was an error!', '', 3000);
          },
        });
      } else {
        let body = {
          startIndex: null,
          count: null,
          groupBy: '',
          sortOrder: '',
          sortBy: '',
          excludeMap: {},
          searchMap: {},
        };
        // let fileType = this.isThisPoliciesPage ? 'policy' : 'evidence'
        let fileType = 'evidence';
        let api = '/audit/' + fileType + '/file/' + fileId + '/download';
        this.genericService.postRequest(api, body, true).subscribe((data: any) => {
          if (data.success) {
            window.location.href = data.data;
            this.snackbarService.success('Downloaded successfully', '', 3000);
          } else {
            this.snackbarService.error(data.message, '', 3000);
          }
        });
      }
    }
  }

  toggleEditor() {
    this.isRequestMoreChanges = !this.isRequestMoreChanges;
    setTimeout(() => {
      if (this.isRequestMoreChanges) {
        this.requestChangesDiv.nativeElement.scrollIntoView();
      }
    }, 200);

  }

  onApproveClick() {
    let payload = this.onStatusChangeCreatePayload();
    payload.status = 'APPROVED';
    this.genericService
      .putRequest(
        `/policies/draftfile/v3/${this.latestDraftFile.draftFileId}`,
        payload
      )
      .subscribe((res) => {
        if (res && res.success) {
          this.snackbarService.success('Approved successfully!', '', 3000);
          this.router.navigate(['/governance/policies']);
        }
      });
  }

  acceptAndSendForApproval() {
    let payload = this.onStatusChangeCreatePayload();
    payload.status = 'APPROVAL_PENDING';
    this.genericService
      .putRequest(
        `/policies/draftfile/v3/${this.latestDraftFile.draftFileId}`,
        payload
      )
      .subscribe((res) => {
        if (res && res.success) {
          this.snackbarService.success(
            'Request has been accepted and send for approval',
            '',
            3000
          );
          this.router.navigate(['/governance/policies']);
        }
      });
  }

  onClickRequestForMoreChanges() {
    let payload = this.onStatusChangeCreatePayload();
    payload.status = 'REVISION_REQUEST';
    this.genericService
      .putRequest(
        `/policies/draftfile/v3/${this.latestDraftFile.draftFileId}`,
        payload
      )
      .subscribe((res) => {
        if (res && res.success) {
          this.snackbarService.success(
            'Change Request send successfully!',
            '',
            3000
          );
          this.router.navigate(['/governance/policies']);
        }
      });
  }

  onStatusChangeCreatePayload() {
    return {
      draftFileId: this.latestDraftFile.draftFileId,
      policyId: this.selectedPolicyId,
      fileId: this.latestDraftFile.fileId,
      status: '',
      policyVersion: this.latestDraftFile.policyVersion,
      draftVersion: this.latestDraftFile.draftVersion,
      changeSummary: this.latestDraftFile.changeSummary,
      requestedChangeSummary: this.content,
    };
  }
}
