import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { GenericService } from '../../shared/genric-service/generic.service';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'app-view-policy',
  templateUrl: './view-policy.component.html',
  styleUrl: './view-policy.component.scss'
})
export class ViewPolicyComponent {

  isViewHtml = false;
  isViewPdf = false;
  isViewImg = false;
  fileName!: string;
  file_location: string = '';
  visible: boolean = false;
  policyRole: 'POLICY_APPROVER' | 'POLICY_OWNER' | 'NONE' = 'NONE';
  singleBu: string = 'false';

  constructor(
    public dialogRef: MatDialogRef<ViewPolicyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: SnackbarService,
    private readonly genericService: GenericService
  ) {

    if (localStorage.getItem('singleBu')?.toString()) {
      this.singleBu = localStorage.getItem('singleBu')?.toString()!;
    }

    const userObjData = JSON.parse(localStorage.getItem('userData')!);
    if (userObjData && userObjData.policyRole) {
      this.policyRole = userObjData.policyRole;
    }
    if (this.data?.selectedPolicy && this.data?.selectedPolicy.fileId && this.data?.selectedPolicy.stgBlobLocation) {
      this.viewFile(this.data?.selectedPolicy);
        if (this.data?.draftFiles_dataCollection && this.data?.draftFiles_dataCollection.length > 0) {
          const filterRecord = this.data?.draftFiles_dataCollection.filter((re: any) => { return re.status === "APPROVED" });
          if (filterRecord && filterRecord.length > 0) {
            
            const d = filterRecord.reduce((a: any, b: any) => {
              return new Date(a.createdTimestamp) > new Date(b.createdTimestamp) ? a : b;
            });
            if (d) {
              data.selectedPolicy.draftVersionTo = d.draftVersion;
            }
          }
        }
    } else {
      if (this.policyRole === 'POLICY_OWNER' && this.data.policyFiles_dataCollection && this.data.policyFiles_dataCollection.length > 0) { // taking latest file from policy files
        const d = this.data.policyFiles_dataCollection.reduce((a: any, b: any) => {
          return new Date(a.createdTimestamp) > new Date(b.createdTimestamp) ? a : b;
        });

        if (d) {
          this.data.selectedPolicy['fileId'] = d.fileId;
          this.data.selectedPolicy['stgBlobLocation'] = d.stgBlobLocation;
          this.data.selectedPolicy['rawFileName'] = d.rawFileName
          this.viewFile(this.data.selectedPolicy);
        }
      } else {
        if (this.data?.draftFiles_dataCollection && this.data?.draftFiles_dataCollection.length > 0) {
          const d = this.data?.draftFiles_dataCollection.reduce((a: any, b: any) => {
            return new Date(a.createdTimestamp) > new Date(b.createdTimestamp) ? a : b;
          });
          if (d) {
            this.data.selectedPolicy['fileId'] = d.fileId;
            this.data.selectedPolicy['stgBlobLocation'] = d.stgBlobLocation;
            this.data.selectedPolicy['rawFileName'] = d.rawFileName
            this.viewFile(this.data.selectedPolicy);
          }
        } else {
          if (this.data?.policyFiles_dataCollection && this.data?.policyFiles_dataCollection.length > 0) {
            const d = this.data?.policyFiles_dataCollection.reduce((a: any, b: any) => {
              return new Date(a.createdTimestamp) > new Date(b.createdTimestamp) ? a : b;
            });
            if (d) {
              this.data.selectedPolicy['fileId'] = d.fileId;
              this.data.selectedPolicy['stgBlobLocation'] = d.stgBlobLocation;
              this.data.selectedPolicy['rawFileName'] = d.rawFileName
              this.viewFile(this.data.selectedPolicy);
            }
          }
        }
      }
    }

  }

  onClose() {
    this.dialogRef.close({ event: 'close' });
  }

  showDialogMaximized(dialog: Dialog) {
    dialog.maximize();
  }

  onHideDialog(val: any) {
    if (!val.maximized) {
      this.visible = false;
    }
  }

  async viewFile(element: any) {

    const all_extensions: any = ['xlsx', 'xls', 'pptx', 'ppt', 'csv', 'docx', 'doc', 'jfif', 'gif', 'jpg', 'jpeg', 'png', 'pdf', 'html', 'eml', 'msg'];

    let lowercaseExtension: any = element.rawFileName?.split('.')[element.rawFileName?.split('.')?.length - 1]?.toLowerCase();

    // let ms_docs: any = ['.xlsx', '.xls', '.pptx', '.ppt', '.docx', '.doc']
    let imgs: any = ['.png', '.jpg', '.jpeg', '.jfif', '.gif']

    // To Test Html view
    // this.fileName = 'dwsample1-html.html';
    // this.file_location = 'COMPLIANCE_EVIDENCE/MANUAL/amal@amaltest.com/2024-08-26T05:07:30.537Z/raw/dwsample1-html.html'
    // // this.blobURL = await this.fileHelperService.viewHTMLHelper('dwsample1-html.html', 'COMPLIANCE_EVIDENCE/MANUAL/amal@amaltest.com/2024-08-26T05:07:30.537Z/raw/dwsample1-html.html');
    // this.isViewHtml = true;

    // To Test PDf view
    // this.fileName = 'Get_Started_With_Smallpdf.pdf';
    // this.file_location = 'COMPLIANCE_EVIDENCE/MANUAL/amal@amaltest.com/2024-09-04T04:02:38.913Z/raw/Get_Started_With_Smallpdf.pdf'
    // // this.blobURL = await this.fileHelperService.viewPDFHelper('Get_Started_With_Smallpdf.pdf', 'COMPLIANCE_EVIDENCE/MANUAL/amal@amaltest.com/2024-09-04T04:02:38.913Z/raw/Get_Started_With_Smallpdf.pdf');
    // this.isViewPdf = true;

    if (element.fileStatus == 'INVALID' || !all_extensions.includes(lowercaseExtension)) {
      return
    } else if (element.rawFileName?.toLowerCase()?.endsWith('.html')) {
      this.fileName = element.rawFileName;
      this.file_location = element.stgBlobLocation;
      this.isViewHtml = true;

    } else if (imgs.includes(element.rawFileName?.toLowerCase()?.substring(element.rawFileName?.lastIndexOf('.')))) {
      this.fileName = element.rawFileName;
      this.file_location = element.stgBlobLocation;
      this.isViewImg = true;

    } else if (element.rawFileName?.toLowerCase()?.endsWith('.pdf')) {
      this.fileName = element.rawFileName;
      this.file_location = element.stgBlobLocation;
      this.isViewPdf = true;

    } else if (element.fileStatus != 'INPROGRESS') {
      if (element.rawFileName?.toLowerCase()?.endsWith('.csv')) {
        this.fileName = element.rawFileName;
        this.file_location = element.uploadLocation;
        this.isViewHtml = true;
      } else {
        this.fileName = element.rawFileName;
        this.file_location = element.uploadLocation;
        this.isViewPdf = true;
      }
    }
  }

  async downloadFile(obj: any) {
    let fileId: any = obj.fileId;
    let file_location: any = obj.stgBlobLocation;
    let rawFileName: any = obj.rawFileName;
    if (fileId && file_location) {
      if (localStorage.getItem('minioAuditConnector')) {
        this.genericService.downloadFileFromMinio(file_location).subscribe({

          next: (blob: Blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = rawFileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.toaster.success('Downloaded successfully', '', 3000);
            // this.spinner.hide();
          },
          error: (error) => {
            // console.error('There was an error!', error);
            this.toaster.error('There was an error!', '', 3000)
          }
        });

      } else {

        let body = {
          startIndex: null,
          count: null,
          groupBy: "",
          sortOrder: "",
          sortBy: "",
          excludeMap: {},
          searchMap: {},
        };
        // let fileType = this.isThisPoliciesPage ? 'policy' : 'evidence'
        let fileType = 'evidence'
        let api = '/audit/' + fileType + '/file/' + fileId + '/download'
        this.genericService.postRequest(api, body, true).subscribe((data: any) => {
          if (data.success) {
            window.location.href = data.data
            this.toaster.success('Downloaded successfully', '', 3000);
          } else {
            this.toaster.error(data.message, '', 3000);
          }
        })
      }
    }

  }
}
