<div class="modal-header" mat-dialog-title>
    <div class="col-8">
        <h3 class="fw-bold">{{data.title}}</h3>
    </div>
    <div class="col-2 d-flex justify-content-end">
        <i class="ph ph-x" style="cursor: pointer;" (click)="onClose()"></i>

    </div>
</div>
<mat-dialog-content>
   
    <form [formGroup]="filtersFrom">
        <div class="seconize-input-filed">
            <div>
                <label>Creator</label>
                <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                    <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                    <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                        formControlName="creator" placeholder="Select Creator" panelClass="seconize-input-dropdown">
                        <mat-option *ngFor="let item of creatorsList" [value]="item.userName">
                            {{item.firstName}} {{item.lastName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <label>Last Updated Date</label>
                <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;">
                    <mat-date-range-input [rangePicker]="createdTime">
                        <input matStartDate formControlName="startDate" placeholder="Start date">
                        <input matEndDate formControlName="endDate" placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="createdTime"></mat-datepicker-toggle>
                    <mat-date-range-picker #createdTime>
                        <mat-date-range-picker-actions>
                            <button mat-button matDateRangePickerCancel class="seconizeButton">Cancel</button>
                            <button mat-raised-button class="seconizeButton" color="primary" matDateRangePickerApply
                                (click)="applyCreatedDate()">Apply</button>
                        </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>

            </div>

            <div>
                <label>Version</label>
                <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;">
                    <input type="text" min="0" matInput pattern="^\d+(\.\d+)*$"
                        placeholder="Add Version" formControlName="version">
                </mat-form-field>
                <small class="error-text" style="font-size: 12px;"
                        *ngIf="filtersFrom.get('version')?.hasError('pattern')">
                        Please enter a valid number (integer or decimal).
                </small>
            </div>

            <div class="mat-form-margin">
                <label>Draft File Status</label>
                <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                    <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                    <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                        formControlName="status" placeholder="Select Status"
                        panelClass="seconize-input-dropdown">
                        <mat-option *ngFor="let item of policyFileStatusList" [value]="item.value">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mat-form-margin">
                <label>Is Draft File Pending ?</label>
                <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                    <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                    <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                        formControlName="isDraftPending" placeholder="Select Draft File Pending Status"
                        panelClass="seconize-input-dropdown">
                        <mat-option *ngFor="let item of booleanList" [value]="item.name">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <label>Does Exception Exist?</label>
                <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                    <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                    <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                        formControlName="doesExceptionExist" placeholder="Select Exception Status"
                        panelClass="seconize-input-dropdown">
                        <mat-option *ngFor="let item of booleanList" [value]="item.name">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div>
                <label>Does the Change Request Exist?</label>
                <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                    <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                    <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                        formControlName="changeRequestExist" placeholder="Select Change Request Exist Status"
                        panelClass="seconize-input-dropdown">
                        <mat-option *ngFor="let item of booleanList" [value]="item.name">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
            <div *ngIf="isAdminBU && singleBu=='false'">
                <label>Business Unit</label>
                <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                    <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                    <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                        formControlName="buId" placeholder="Select BU Name" panelClass="seconize-input-dropdown">
                        <mat-option *ngFor="let bu of buList" [value]="bu.id">
                            {{bu.name}}
                        </mat-option> </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="audit-footer-btn">
        <button mat-fab extended class="cancel-btn btn-icon-txt btn" (click)="resetFilter()">
            Reset
        </button>
        <button mat-fab extended class="primary-btn btn-icon-txt btn" [ngClass]="{'disabled-btn': filtersFrom.invalid}" [disabled]="filtersFrom.invalid" (click)="onApply()">
            Apply
        </button>
    </div>
</mat-dialog-actions>