<ng-container *ngIf="!userPermissions || userPermissions.permission === 'NONE'; else mainView">
    No Permission to view this page
</ng-container>

<ng-template #mainView>
    <section [ngClass]="isDarkMode ? 'banner' : ''" #mySection>
        <div class="header">
            <div class="header-left">
                <div class="row">
                    <div class="file-logo flex-center dark-bg">
                        <i class="ph ph-file-text " style="font-size: 24px;"></i>
                    </div>
                    <div class="file-name">
                        <div class="file-name dark-heading">{{selectedPolicy?.name}}</div>
                    </div>
                </div>
                <div class="version dark-bg">
                    v{{selectedPolicy?.version}}
                </div>
            </div>
            <div class="header-right seconize-btn">
                <i [routerLink]="'/governance/policies'" routerLinkActive="active" class="ph ph-x"
                    style="cursor: pointer;font-size: 22px;margin-left: 18px;"></i>
            </div>
        </div>
        <!-- <div class="policy-header flex-center">
            <div class="col-7 row">
                <div class="logo">
                    <img *ngIf="logo" [src]=logo alt="">
                    <img *ngIf="!logo" matTooltip="Seconize" src="../../../../assets/images/login/seconize-logo.png"
                        alt="">
                </div>
                <label class="fs-18 light-grey dark-txt"
                    style="white-space: nowrap; padding-right: 23px; padding-left: 12px;">Policies for
                    {{canApprovePolicy ? 'approval' : 'review'}}</label>
                <div class="seconize-input-filed d-flex" style="min-width: 253px;" *ngIf="selectedPolicy">
                    <mat-form-field appearance="outline" style="width: 100%;" class="input-dropdown">
                        <i class="ph ph-caret-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select placeholder="Acceptable Use Policy" panelClass="seconize-input-dropdown"
                            [(ngModel)]="selectedPolicyId" (selectionChange)="onPolicyChange()">
                            <mat-option *ngFor="let data of policiesDropdownLists" [value]='data.id'>
                                {{data?.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="col-3">
                <p [routerLink]="'/governance/policies'" routerLinkActive="active"
                    style="margin-bottom: 0; padding-right: 77px;"
                    class="fs-16 fw-600 link cursor-pointer d-flex justify-content-end ">GO TO DASHBOARD</p>
            </div>
        </div> -->

        <div class="policy-body-wrapper">
            <div class="policy-body">
                <div class="d-flex review-class" style="gap: 40px; align-items: start;">

                    <div id="leftSection" class="col-6 right-side-policy" style="padding-left: 29px;">
                        <div>
                            <h1 class="title dark-heading">{{selectedPolicy?.name || '--'}}</h1>
                            <h2 class="key-title border-bottom">Key Information</h2>
                            <div class="row border-bottom" style="padding-bottom: 24px; margin-bottom: 31px;">
                                <div class="info" style="width: 33.33%;">
                                    <div class="subtitle">Business Unit</div>
                                    <div class="info-value">{{selectedBu?.name ? selectedBu.name : '--'}}</div>
                                </div>
                                <div class="info" style="width: 33.33%;">
                                    <div class="subtitle">Owner</div>
                                    <div class="info-value"
                                        [matTooltip]="selectedPolicy?.assigneeFullName ? selectedPolicy?.assigneeFullName : selectedPolicy?.createdBy ? selectedPolicy.createdBy : ''">
                                        {{selectedPolicy?.assigneeFullName ? selectedPolicy?.assigneeFullName :
                                        selectedPolicy?.createdBy ? selectedPolicy.createdBy :
                                        '--'}}
                                    </div>
                                </div>
                                <div class="info" style="width: 33.33%;">
                                    <div class="subtitle">Reviewer</div>
                                    <div class="info-value"
                                        [matTooltip]="selectedPolicy?.l1ApproverFullName ? selectedPolicy?.l1ApproverFullName : ''">
                                        {{selectedPolicy?.l1ApproverFullName ? selectedPolicy?.l1ApproverFullName :
                                        '--'}}</div>
                                </div>
                            </div>
                            <div class="row border-bottom" style="padding-bottom: 24px; margin-bottom: 31px;">
                                <div class="info" style="width: 33.33%;">
                                    <div class="subtitle">Approver</div>
                                    <div class="info-value"
                                        [matTooltip]="selectedPolicy?.l2ApproverFullName ? selectedPolicy?.l2ApproverFullName : ''">
                                        {{selectedPolicy?.l2ApproverFullName ? selectedPolicy?.l2ApproverFullName :
                                        '--'}}</div>
                                </div>
                                <div class="info" style="width: 33.33%;">
                                    <div class="subtitle">Draft</div>
                                    <div class="info-value">{{draftVersion ? 'v' + draftVersion :'--'}}</div>
                                </div>
                                <div class="info" style="width: 33.33%;">
                                    <div class="subtitle">Updated On</div>
                                    <div class="info-value">{{selectedPolicy?.updatedTimestamp | date : 'mediumDate'}}
                                    </div>
                                </div>
                            </div>

                            <div class="use-policy-box postion-relative">
                                <div class="row justify-content-end gap-1"
                                    *ngIf="!!selectedPolicy?.fileId && !!selectedPolicy?.stgBlobLocation">
                                    <!-- <mat-icon class="dark-blue fs-20 cursor-pointer" style="height: 20px;">share</mat-icon> -->
                                    <i (click)="downloadFile()"
                                        class="ph ph-download-simple dark-blue stroke fs-20 cursor-pointer"></i>
                                    <!-- <i class="ph ph-link-simple dark-blue stroke fs-20 cursor-pointer"></i> -->
                                    <mat-icon class="dark-blue fs-2 cursor-pointer fs-20" (click)="visible = true"
                                        style="height: 20px;">
                                        zoom_out_map</mat-icon>
                                </div>

                            </div>

                            <div class="accept-policy-box" style="margin-bottom: 35px;">

                                <!-- {{selectedPolicy?.description}} -->
                                <ng-container *ngIf="selectedPolicy">
                                    <ng-container *ngTemplateOutlet="fileViwerTemplate"></ng-container>
                                </ng-container>


                                <p-dialog (onShow)="showDialogMaximized(dialDetail)" (onMaximize)="onHideDialog($event)"
                                    #dialDetail [(visible)]="visible" [maximizable]="true">
                                    <ng-container *ngTemplateOutlet="fileViwerTemplate"></ng-container>
                                </p-dialog>


                                <ng-template #fileViwerTemplate>
                                    @if(selectedPolicy?.fileStatus!='INVALID' && selectedPolicy?.fileId){

                                    @if(((selectedPolicy.rawFileName | matchFileExtension) ?
                                    selectedPolicy.fileStatus == 'VALID' : true) && (selectedPolicy?.rawFileName |
                                    fileExtension)) {

                                    <ng-container *ngIf="isViewImg">
                                        <app-img-viewer class="img-viewer" [isDialog]="false" [fileName]="fileName"
                                            [file_location]="file_location"></app-img-viewer>
                                    </ng-container>

                                    <ng-container *ngIf="isViewHtml">
                                        <app-html-viewer class="img-viewer" [isDialog]="false" [fileName]="fileName"
                                            [file_location]="file_location"></app-html-viewer>
                                    </ng-container>

                                    <ng-container *ngIf="isViewPdf">
                                        <app-pdf-viewer [isDialog]="false" [fileName]="fileName"
                                            [file_location]="file_location"> </app-pdf-viewer>
                                    </ng-container>

                                    }

                                    @if(((selectedPolicy.rawFileName | matchFileExtension) &&
                                    selectedPolicy.fileStatus != 'VALID') || !(selectedPolicy.rawFileName |
                                    fileExtension)){
                                    @if(selectedPolicy.fileStatus=='UNSUPPORTED' || !(selectedPolicy.rawFileName |
                                    fileExtension)){
                                    <i class="ph ph-warning action-icon"
                                        (click)="$event.stopPropagation();viewFile(selectedPolicy)"
                                        [matTooltip]="!(selectedPolicy.rawFileName | fileExtension)?'Unsupported file extension':'Unsupported'">
                                    </i>

                                    }@else if(selectedPolicy.fileStatus=='FAILED'){
                                    <mat-icon svgIcon="DRM_Failed" class="mr-10px"
                                        (click)="$event.stopPropagation();viewFile(selectedPolicy)" matTooltip="Failed">
                                    </mat-icon>

                                    }@else if(selectedPolicy.fileStatus=='INPROGRESS'){
                                    <i class="ph ph-hourglass action-icon" matTooltip="Processing DRM">
                                    </i>
                                    }}
                                    <!-- <div>Processing DRM</div> -->
                                    } @else {

                                    <span class="mr-2"> No Policy To View. </span>
                                    }
                                </ng-template>
                            </div>
                        </div>

                        <div style="margin-left: 28px;"
                            *ngIf="!!selectedPolicy && !noActionFlag && userPermissions && userPermissions.permission === 'MODIFY'">
                            <h1 *ngIf="!canApprovePolicy" class="approval-txt dark-heading">Is this version good to be
                                send for approval?
                            </h1>
                            <h1 *ngIf="canApprovePolicy" class="approval-txt dark-heading">Is this version good to be
                                approved?</h1>

                            <div class="seconize-btn d-flex gap-1" style="margin-bottom: 49px;">
                                <button mat-flat-button [ngClass]="{'disabled-btn': isRequestMoreChanges}"
                                    *ngIf="!canApprovePolicy && selectedPolicy?.fileId && selectedPolicy?.stgBlobLocation"
                                    [disabled]="isRequestMoreChanges" class="primary-btn  light-primary-btn btn"
                                    (click)="acceptAndSendForApproval()"><span style="color: #F7FAFC;">Accept and send
                                        for
                                        approval</span></button>
                                <button [ngClass]="{'disabled-btn': isRequestMoreChanges}"
                                    [disabled]="isRequestMoreChanges" mat-flat-button
                                    *ngIf="canApprovePolicy && selectedPolicy?.fileId && selectedPolicy?.stgBlobLocation"
                                    class="primary-btn light-primary-btn btn" (click)="onApproveClick()"><span
                                        style="color: #F7FAFC;">Yes, Approve</span></button>

                                <button [ngClass]="{'disabled-btn': isRequestMoreChanges}"
                                    [disabled]="isRequestMoreChanges" (click)="toggleEditor()" mat-fab extended
                                    class="btn-icon-outline btn-icon-txt btn">
                                    Request more changes
                                </button>
                            </div>
                        </div>

                        <div style="margin-bottom: 30px;"
                            *ngIf="isRequestMoreChanges && userPermissions && userPermissions.permission === 'MODIFY'"
                            #requestChangesDiv>
                            <div class="row justify-space-between" style="margin-bottom: 18px;">
                                <p class="mb-0 fs-18 fw-600">Request for changes</p>
                                <i class="ph ph-x stroke" style="cursor: pointer;"
                                    (click)="isRequestMoreChanges = false"></i>
                            </div>

                            <st-editor [(ngModel)]="content" [config]="config" class="policy-text-editor"></st-editor>
                            <div class="seconize-btn" style="margin-top: 35px;">
                                <button mat-flat-button class="primary-btn light-primary-btn btn"
                                    style="color: #F7FAFC;" (click)="onClickRequestForMoreChanges()">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-4 fixed-right-box">
                        <div class="changes-required" style="margin-bottom: 44px;">
                            <h2 class="dark-heading">Changes Summary</h2>
                            <div class="changes-box">
                                <p [innerHTML]="changeRequested || '--'"></p>
                            </div>
                        </div>

                        <div class="changes-required">
                            <h2 class="dark-heading">Draft History</h2>
                            <div class="draft-wrapper">
                                <div *ngFor="let draft of draftFiles_dataCollection" class="changes-box draft-box">
                                    <p class="fs-16 light-blue"
                                        style="font-weight: 500;margin-bottom: 0;line-height: 24px;">
                                        Version {{draft?.draftVersion}}</p>
                                    <p class="dark-blue lh-21" style="margin-bottom: 0;">Uploaded by
                                        {{draft?.createdBy}}
                                    </p>
                                    <p class="dark-blue lh-21" style="margin-bottom: 0;">{{draft?.createdTimestamp |
                                        date:'MMM
                                        dd,yyyy'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</ng-template>