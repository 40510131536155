<!-- TODO : remove unwanted images -->
<app-login-wrapper [page]="'login'">
  <form [formGroup]="loginForm">
    <div class="flex flex-column">
      <label>User ID</label>
      <input
        type="text"
        pInputText
        formControlName="email"
        placeholder="Enter user ID"
        maxlength="360"
      />

      <small class="error-text">
        {{ checkError("email") }}
      </small>
    </div>

    <div class="flex flex-column">
      <label>Password</label>
      <p-password
        formControlName="password"
        [feedback]="false"
        [toggleMask]="true"
        placeholder="Password"
      />

      <small class="error-text">
        {{ checkError("password") }}
      </small>
    </div>

    <div class="forgot-pwd">
      <span (click)="navigateToForgotPassword()">Forgot Password?</span>
    </div>

    @if(loginForm.controls['email'].value == 'support@drc.seconize.co'){
    <div class="flex flex-column gap-2 mb-24">
      <label for="server">Server</label>
      <p-selectbutton
        [options]="servers"
        formControlName="server"
        optionLabel="label"
        optionValue="value"
        aria-labelledby="basic"
        [allowEmpty]="false"
      />
    </div>
    }

    <p-button
      label="Submit"
      pRipple
      label="Sign In"
      (click)="userLogin()"
      [disabled]="loginForm.invalid || reCaptchaResponse == ''"
    />
  </form>
  <div>
    <p-divider align="center" type="dotted">
      <b>Or</b>
    </p-divider>

    <div class="row justify-space-between gap-8">
      <asl-google-signin-button
        size="large"
        id="g_id_onload"
        type="standard"
        shape="rectangular"
        hd="gmail.com"
        logo_alignment="center"
        theme="outline"
      ></asl-google-signin-button>

      <button class="octa-btn row gap-10" (click)="oktaLogin()">
        <img
          class="okta-logo"
          src="assets/images/login/okta_icon_dark.svg"
          alt=""
        />
        <img
          class="octa-logo-with-name"
          src="assets/images/login/okta_logo_dark.svg"
          alt="Okta"
        />
      </button>

      <button class="azure-btn row gap-10" (click)="azureAdLogin()">
        <img
          src="https://opensource.microsoft.com/assets/images/svgs/microsoft-logo-no-text.svg"
          alt="Azure AD"
        />
        <span> Azure AD </span>
      </button>
    </div>
  </div></app-login-wrapper
>

<div class="login-wrapper" *ngIf="false">
  <div class="sections row">
    <div class="left-section">
      <div class="seconize-logo">
        <img
          style="width: 150px"
          src="../../../assets/images/login/seconize-logo.png"
          alt=""
        />
      </div>
      <div class="derisk-logo">
        <img
          style="width: 150px"
          src="../../../assets/images/login/logo-new.png"
          alt=""
        />
        <div class="welcome-text">
          <span>Welcome Back!</span> 👋
          <!-- hi emoji -->
        </div>
      </div>

      <form [formGroup]="loginForm">
        <div class="flex flex-column">
          <label>User ID</label>
          <input
            type="text"
            pInputText
            formControlName="email"
            placeholder="Enter user ID"
            maxlength="360"
          />

          <small class="error-text">
            {{ checkError("email") }}
          </small>
        </div>

        <div class="flex flex-column">
          <label>Password</label>
          <p-password
            formControlName="password"
            [feedback]="false"
            [toggleMask]="true"
          >
          </p-password>

          <small class="error-text">
            {{ checkError("password") }}
          </small>
        </div>

        <div class="forgot-pwd" (click)="navigateToForgotPassword()">
          Forgot Password?
        </div>
        <p-button
          label="Submit"
          pRipple
          label="Sign In"
          (click)="userLogin()"
          [disabled]="loginForm.invalid || reCaptchaResponse == ''"
        />
      </form>
      <div>
        <p-divider align="center" type="dotted">
          <b>Or</b>
        </p-divider>

        <div class="row gap-8 sign-btn-wrapper seconize-btn">
          <button class="octa-btn row gap-8">
            <img
              class="okta-logo"
              src="assets/images/login/okta_icon_dark.svg"
              alt=""
            />
            <img
              class="octa-logo-with-name"
              src="assets/images/login/okta_logo_dark.svg"
              alt="Okta"
            />
          </button>

          <button class="azure-btn row gap-8">
            <img
              src="https://opensource.microsoft.com/assets/images/svgs/microsoft-logo-no-text.svg"
              alt="Azure AD"
            />
            <span> Azure AD </span>
          </button>
        </div>

        <!-- </div> -->
      </div>
    </div>

    <div class="right-section">
      <img
        src="../../../assets/images/login/placeholder-charts.png"
        alt="placeholder-charts"
        class="placeholder-charts"
      />
    </div>
  </div>

  <!-- --------------------------- old code -------------------------- -->
  <div class="login-wrapper" *ngIf="!is2FAPage && false">
    <div class="column-shape">
      <img src="../../../assets/images/login/Column.png" alt="" />
    </div>
    <div class="container-fluid">
      <img
        src="../../../assets/images/login/seconize-logo.png"
        alt=""
        class="header-logo"
      />

      <div class="row" style="gap: 40px">
        <div class="col-2 left-side">
          <div class="image-side">
            <img
              src="../../../assets/images/login/login-wraper.png"
              alt=""
              class="responsive-img"
            />
          </div>
          <div class="shape-txt">
            <h4>Lorem Ipsum</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
        <div class="col-8">
          <div class="login-card">
            <div class="card-header">
              <img
                class="img-cls"
                src="../../../assets/images/login/logo-new.png"
                alt=""
              />
            </div>
            <div class="card-body row">
              <div class="col-5">
                <div class="seconize-input-filed">
                  <!-- <div>
                      <label class="login-label">User ID</label>
                      <mat-form-field
                        appearance="outline"
                        style="
                          width: 100%;
                          margin-top: 4px;
                          margin-bottom: 10px;
                        "
                      >
                        <input
                          autocomplete="chrome-off"
                          formControlName="email"
                          type="text"
                          matInput
                          placeholder="Enter user ID"
                          maxlength="360"
                        />
                        <mat-error
                          *ngIf="
                            loginForm.controls['email'].hasError('email') &&
                            loginForm.controls['email'].errors &&
                            !loginForm.controls['email'].errors['required']
                          "
                        >
                          Please enter a valid email address
                        </mat-error>
                        <mat-error
                          *ngIf="
                            loginForm.controls['email'].errors &&
                            loginForm.controls['email'].errors['required']
                          "
                        >
                          User ID is required
                        </mat-error>
                        <mat-error
                          *ngIf="
                            loginForm.controls['email'].hasError('maxlength')
                          "
                        >
                          User ID cannot exceed 360 characters
                        </mat-error>
                      </mat-form-field>
                    </div> -->

                  <!-- <div>
                      <label class="login-label">Password</label>
                      <mat-form-field
                        appearance="outline"
                        style="width: 100%; margin-top: 4px"
                        [ngStyle]="{
                          'margin-bottom': showDropdown ? '5px' : '10px'
                        }"
                      >
                        <input
                          autocomplete="chrome-off"
                          formControlName="password"
                          [type]="passwordVisible ? 'text' : 'password'"
                          matInput
                          placeholder="Enter password"
                        />
                        <button
                          type="button"
                          mat-icon-button
                          matSuffix
                          (click)="passwordVisible = !passwordVisible"
                          [attr.aria-label]="'Hide password'"
                          [attr.aria-pressed]="!passwordVisible"
                        >
                          <mat-icon>{{
                            passwordVisible ? "visibility" : "visibility_off"
                          }}</mat-icon>
                        </button>
                        <mat-error
                          *ngIf="
                            loginForm.controls['password'].errors &&
                            loginForm.controls['password'].errors['required']
                          "
                        >
                          Password is required
                        </mat-error>
                        <mat-error
                          *ngIf="
                            loginForm.controls['password'].hasError('minlength')
                          "
                        >
                          Password must be at-least 8 characters
                        </mat-error>
                        <mat-error
                          *ngIf="
                            loginForm.controls['password'].hasError('maxlength')
                          "
                        >
                          Password cannot exceed 24 characters
                        </mat-error>
                      </mat-form-field>
                    </div> -->

                  <!-- <div
                      [ngStyle]="{
                        'margin-bottom': showDropdown ? '5px' : '21px'
                      }"
                    >
                      <a class="link" (click)="navigateToForgotPassword()"
                        >Forgot password?</a
                      >
                    </div> -->
                  <!-- <div
                      class="seconize-input-filed"
                      *ngIf="showDropdown"
                      [ngStyle]="{
                        'margin-bottom': showDropdown ? '11px' : ''
                      }"
                    > -->
                  <!-- <mat-form-field
                        appearance="outline"
                        style="width: 100%; margin-top: 4px"
                      >
                        <mat-select
                          formControlName="server"
                          panelClass="seconize-select-dropdown"
                        >
                          <mat-option value="" selected disabled
                            >Server</mat-option
                          >
                          <mat-option value="SECONIZE">Seconize</mat-option>
                          <mat-option value="LNT">Larsen Toubro</mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="
                            loginForm.controls['server'].errors &&
                            loginForm.controls['server'].errors['required']
                          "
                        >
                          Please select a server to proceed.
                        </mat-error>
                      </mat-form-field> -->
                  <!-- </div> -->

                  <div class="seconize-btn">
                    <!-- <button
                        [ngClass]="
                          (loginForm.invalid && !enableSubmitButton) ||
                          (isASMBranch ? false : reCaptchaResponse == '')
                            ? 'btn-disabled'
                            : ''
                        "
                        mat-flat-button
                        [disabled]="
                          (loginForm.invalid && !enableSubmitButton) ||
                          (isASMBranch ? false : reCaptchaResponse == '')
                        "
                        class="primary-btn btn"
                        style="width: 100%"
                        (click)="userLogin()"
                      >
                        Login
                      </button> -->
                  </div>
                </div>
                <!-- </form> -->
              </div>
              <div class="col-1">
                <div class="or-class">
                  <!-- <mat-divider vertical class="line"></mat-divider>
                  <div class="center">
                    <div class="circle">OR</div>
                  </div>
                  <mat-divider vertical class="line"></mat-divider> -->
                </div>
              </div>
              <div class="col-4">
                <div class="sign-btn-wrapper seconize-btn">
                  <button
                    (click)="azureAdLogin()"
                    mat-stroked-button
                    class="btn secondary-outline"
                    style="
                      height: 40px !important;
                      width: 300px;
                      color: #6b6d72 !important;
                      font-family: 'Source Sans 3' !important;
                      font-weight: 600;
                    "
                  >
                    <img
                      style="width: 25px"
                      src="https://opensource.microsoft.com/assets/images/svgs/microsoft-logo-no-text.svg"
                      alt=""
                    />
                    Sign in with Azure Ad
                  </button>

                  <button
                    (click)="oktaLogin()"
                    mat-stroked-button
                    class="btn secondary-outline"
                    style="
                      width: 300px;
                      height: 40px;
                      font-family: 'Source Sans 3' !important;
                      color: #6b6d72 !important;
                      font-weight: 600;
                    "
                  >
                    <div style="position: relative">
                      <img
                        style="
                          width: 25px;
                          position: absolute;
                          left: -26px;
                          top: -13px;
                        "
                        src="assets/images/login/okta_icon_dark.svg"
                        alt=""
                      />
                    </div>
                    Sign in with
                    <img
                      style="width: 41px"
                      src="assets/images/login/okta_logo_dark.svg"
                      alt=""
                    />
                  </button>

                  <!-- <asl-google-signin-button
                    [width]="buttonWidth"
                    text="continue_with"
                    theme="outline"
                    size="large"
                    id="g_id_onload"
                    type="standard"
                    shape="rectangular"
                    hd="gmail.com"
                    logo_alignment="center"
                  ></asl-google-signin-button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="grey-txt copy-txt">
      Powered by <span class="fw-700 black-txt">Seconize</span> v3.0.0
    </p>
  </div>
  <!-- <ng-container *ngIf="is2FAPage && false">
    <app-two-factor-verification
      [email]="loginForm.value.email"
      [server]="loginForm.value.email"
      (change2FA)="emit2FA($event)"
    ></app-two-factor-verification>
  </ng-container> -->
</div>
