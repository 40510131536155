<div class="common-popup-header row dark-black" style="margin: 8px;">
        <div class="left-header col-9 row">
            <div class="version-name">
                Version {{data?.selectedPolicy?.lastApprovedPolicyVersion ?
                data?.selectedPolicy?.lastApprovedPolicyVersion : data?.selectedPolicy?.version ||
                data?.selectedPolicy?.policyVersion || '--'}}
            </div>
            <div>
                <p style="margin-bottom: 0;" class="blue-txt lh-21">Reviewer</p>
                <p style="margin-bottom: 0;">{{data?.selectedPolicy?.l1ApproverFullName ?
                    data?.selectedPolicy?.l1ApproverFullName :
                    data?.reviewer || '--'}}</p>
            </div>
    
            <div>
                <p style="margin-bottom: 0;" class="blue-txt lh-21">Renew on</p>
                <p style="margin-bottom: 0;"><span *ngIf="data?.selectedPolicy?.targetDate; else blankVal">
                        {{data?.selectedPolicy?.targetDate | date :'mediumDate'}}
                    </span>
                    <ng-template #blankVal>
                        <span *ngIf="data?.targetDate; else blankVal2">
                            {{data?.targetDate | date :'mediumDate'}}
                        </span>
                        <ng-template #blankVal2>
                            --
                        </ng-template>
                    </ng-template>
                </p>
            </div>
    
        </div>
        <div (click)="onClose()" class="right-header col-1 d-flex justify-content-end ">
            <i class="ph ph-x" style="cursor: pointer; font-size: 20px;"></i>
        </div>
    
</div>
<mat-dialog-content>

    <div class="d-flex" style="gap: 40px; align-items: start;">
        <div class="col-6" style="padding-left: 29px;">
            <h1 class="title">{{data?.selectedPolicy?.name}}</h1>
            <!-- <h2 class="key-title border-bottom">Key Information</h2>
            <div class="row border-bottom" style="padding-bottom: 24px; margin-bottom: 20px;">
                <div class="info" style="width: 33.33%;">
                    <div class="subtitle">Business Unit</div>
                    <div class="info-value">{{data?.selectedBu?.name ? data?.selectedBu.name : '--'}}</div>
                </div>
                <div class="info" style="width: 33.33%;">
                    <div class="subtitle">Owner</div>
                    <div class="info-value">{{data?.selectedPolicy?.assigneeFullName ?
                        data?.selectedPolicy?.assigneeFullName : data?.selectedPolicy?.createdBy ?
                        data?.selectedPolicy.createdBy : '--'}}
                    </div>
                </div>
                <div class="info" style="width: 33.33%;">
                    <div class="subtitle">Draft</div>
                    <div class="info-value">v{{data?.selectedPolicy?.draftVersionTo ||
                        data?.selectedPolicy?.draftVersion || '--'}}</div>
                </div>

            </div> -->

            <div class="use-policy-box postion-relative ">
                <div class="row justify-content-end gap-1"
                    *ngIf="!!data?.selectedPolicy?.fileId && !!data?.selectedPolicy?.stgBlobLocation">
                    <!-- <mat-icon class="dark-blue fs-20 cursor-pointer" style="height: 20px;">share</mat-icon> -->
                    <i (click)="downloadFile(data?.selectedPolicy)"
                        class="ph ph-download-simple dark-blue stroke fs-20 cursor-pointer"></i>
                    <!-- <i class="ph ph-link-simple dark-blue stroke fs-20 cursor-pointer"></i> -->
                    <mat-icon class="dark-blue fs-2 cursor-pointer fs-20" (click)="visible = true;"
                        style="height: 20px;"> zoom_out_map</mat-icon>
                </div>

            </div>

            <div class="accept-policy-box dark-black border-dark">
                <p-dialog (onShow)="showDialogMaximized(dialDetail)" (onMaximize)="onHideDialog($event)" #dialDetail
                [(visible)]="visible" [maximizable]="true">
                <ng-container *ngTemplateOutlet="fileViwerTemplate"></ng-container>
            </p-dialog>
                <div>
                    <ng-container *ngIf="data?.selectedPolicy">
                        <ng-container *ngTemplateOutlet="fileViwerTemplate"></ng-container>
                    </ng-container>
                    <ng-template #fileViwerTemplate>
                        @if(data?.selectedPolicy?.fileStatus!='INVALID' && data?.selectedPolicy?.fileId){

                        @if(((data?.selectedPolicy.rawFileName | matchFileExtension) ?
                        data?.selectedPolicy.fileStatus == 'VALID' : true) && (data?.selectedPolicy?.rawFileName |
                        fileExtension)) {

                        <ng-container *ngIf="isViewImg">
                            <app-img-viewer class="img-viewer" [isDialog]="false" [fileName]="fileName"
                                [file_location]="file_location"></app-img-viewer>
                        </ng-container>

                        <ng-container *ngIf="isViewHtml">
                            <app-html-viewer class="img-viewer" [isDialog]="false" [fileName]="fileName"
                                [file_location]="file_location"></app-html-viewer>
                        </ng-container>

                        <ng-container *ngIf="isViewPdf && !visible">
                            <app-pdf-viewer [isDialog]="false" [fileName]="fileName" [file_location]="file_location" class="pdf-viewer">
                            </app-pdf-viewer>
                        </ng-container>
                        <ng-container *ngIf="isViewPdf && visible">
                            <app-pdf-viewer [isDialog]="false" [fileName]="fileName" [file_location]="file_location">
                            </app-pdf-viewer>
                        </ng-container>

                        }

                        @if(((data?.selectedPolicy.rawFileName | matchFileExtension) &&
                        data?.selectedPolicy.fileStatus != 'VALID') || !(data?.selectedPolicy.rawFileName |
                        fileExtension)){
                        @if(data?.selectedPolicy.fileStatus=='UNSUPPORTED' || !(data?.selectedPolicy.rawFileName |
                        fileExtension)){
                        <i class="ph ph-warning action-icon"
                            (click)="$event.stopPropagation();viewFile(data?.selectedPolicy)"
                            [matTooltip]="!(data?.selectedPolicy.rawFileName | fileExtension)?'Unsupported file extension':'Unsupported'">
                        </i>
                        <ng-container *ngIf="isViewImg">
                            <app-img-viewer class="img-viewer" [isDialog]="false" [fileName]="fileName"
                                [file_location]="file_location"></app-img-viewer>
                        </ng-container>

                        <ng-container *ngIf="isViewHtml">
                            <app-html-viewer class="img-viewer" [isDialog]="false" [fileName]="fileName"
                                [file_location]="file_location"></app-html-viewer>
                        </ng-container>

                        <ng-container *ngIf="isViewPdf">
                            <app-pdf-viewer [isDialog]="false" [fileName]="fileName" [file_location]="file_location">
                            </app-pdf-viewer>
                        </ng-container>


                        }@else if(data?.selectedPolicy.fileStatus=='FAILED'){
                        <mat-icon svgIcon="DRM_Failed" class="mr-10px"
                            (click)="$event.stopPropagation();viewFile(data?.selectedPolicy)" matTooltip="Failed">
                        </mat-icon>
                        <ng-container *ngIf="isViewImg">
                            <app-img-viewer class="img-viewer" [isDialog]="false" [fileName]="fileName"
                                [file_location]="file_location"></app-img-viewer>
                        </ng-container>

                        <ng-container *ngIf="isViewHtml">
                            <app-html-viewer class="img-viewer" [isDialog]="false" [fileName]="fileName"
                                [file_location]="file_location"></app-html-viewer>
                        </ng-container>

                        <ng-container *ngIf="isViewPdf">
                            <app-pdf-viewer [isDialog]="false" [fileName]="fileName" [file_location]="file_location">
                            </app-pdf-viewer>
                        </ng-container>
                        }@else if(data?.selectedPolicy.fileStatus=='INPROGRESS'){
                        <i class="ph ph-hourglass action-icon" matTooltip="Processing DRM">
                        </i>
                        Processing DRM
                        }}

                        } @else {

                        <span class="mr-2"> No Policy To View. </span>
                        }
                    </ng-template>
                </div>
            </div>
        </div>

        <div class="col-4" style="padding-right: 29px;">

            <div class="changes-required dark-box-shadow" style="margin-bottom: 35px;">
                <h2>Key Information</h2>
                <div class="changes-box dark-black" style="padding: 20px;">
                    <div class="d-flex fs-14">
                        <div class="info" style="width: 33.33%;" *ngIf="singleBu == 'false'">
                            <div class="subtitle fw-600">Business Unit</div>
                            <div class="info-value">{{data?.selectedBu?.name ? data?.selectedBu.name : '--'}}</div>
                        </div>
                        <div class="info" style="width: 33.33%;">
                            <div class="subtitle fw-600">Owner</div>
                            <div class="info-value">{{
                                data?.isDraft ? data?.selectedPolicy?.userFullName : 
                                data?.selectedPolicy?.assigneeFullName ?
                                data?.selectedPolicy?.assigneeFullName : data?.selectedPolicy?.createdBy ?
                                data?.selectedPolicy.createdBy : '--'}}
                            </div>
                        </div>
                        <div class="info" style="width: 33.33%;">
                            <div class="subtitle fw-600">Draft</div>
                            <div class="info-value">{{data?.selectedPolicy?.draftVersion ? 'v'+data?.selectedPolicy?.draftVersion:  '--'}}</div>
                        </div>
        
                    </div>
                </div>
            </div>

            <div class="changes-required dark-box-shadow">
                <h2>Change Summary</h2>
                <div class="changes-box dark-black">
                    <p [innerHTML]="data?.changeRequested"></p>
                </div>
            </div>
            <div class="changes-required dark-box-shadow" style="margin-top: 20px;"
                *ngIf="data.isDraft == true && data.draftStatus === 'REVISION_REQUEST'">
                <h2>Changes Requested</h2>
                <div class="changes-box dark-black">
                    <p [innerHTML]="data?.requestedChangeSummary"></p>
                </div>
            </div>
        </div>
    </div>

</mat-dialog-content>