<div class="login-wrapper">
  <div class="sections row">
    <div class="left-section center">
      <div class="form-section">
        <div class="seconize-logo">
          <img
            style="width: 150px"
            src="../../../assets/images/login/seconize-logo.png"
            alt=""
          />
        </div>
        <div class="derisk-logo">
          <img
            style="width: 150px"
            src="../../../assets/images/login/logo-new.png"
            alt=""
          />
          <div class="welcome-text">
            @if(page === 'login'){
            <span>Welcome Back!</span> 👋 }@else if(page === 'forgot-pwd'){
            <span>Forgot Password?</span> 🤔 }@else if(page === 'otp'){
            <span>Verify OTP</span> 📲 }
          </div>
        </div>

        <!-- Components (Login, Forgot Password, Verifty OTP) -->
        <ng-content></ng-content>
      </div>
    </div>

    <div class="right-section center">
      <div>
        <div class="image-text">
          <div class="typing-container">
            <span>Automate </span>
            <div
              style="margin-left: 5px; font-weight: bold"
              class="typing-text"
            >
              <span class="typed-text"></span>
              <span class="cursor">&nbsp;</span>
            </div>
          </div>
          <br />
          <div style="font-size: 15px; margin-top: 5px">
            Turbo charge your GRC operations.
          </div>
        </div>

        <img
          src="../../../assets/images/login/login.svg"
          alt="placeholder-charts"
          class="placeholder-charts"
        />
      </div>
    </div>
  </div>
</div>
