import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-policy-draft',
  templateUrl: './upload-policy-draft.component.html',
  styleUrl: './upload-policy-draft.component.scss'
})
export class UploadPolicyDraftComponent {

}
