<div class="modal-header"  mat-dialog-title>
    <div class="col-8">
        <h3 class="fw-bold" style="margin: 0;">All Filters</h3>
    </div>
    <div class="col-2 d-flex justify-content-end">
        <i class="ph ph-x" style="cursor: pointer;" (click)="onClose()"></i>

    </div>
</div>
<mat-dialog-content>
    
    <form [formGroup]="filtersForm">
        <div class="seconize-input-filed">
            <div class="seconize-dropdown-container gap-10">
                <div class="seconize-dropdown">
                    <label>State</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="selectedState" placeholder="Select State"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of stateList" [value]="item.value">{{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="seconize-dropdown">
                    <label>Treatment</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="selectedTreatment" placeholder="Select Treatment"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of treatmentList" [value]="item.value">{{item.name}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>

                <!-- </div> -->
            </div>
            <div class="seconize-dropdown-container gap-10">
                <div class="seconize-dropdown">
                    <label>Managed By</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="selectedManagedBy" placeholder="Select Managed by"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of managedByUserList" [value]="item.value">{{ item.label }}
                            </mat-option>
                            <mat-option *ngIf="managedByUserList.length<=0">
                                {{ isUsersLoading?'Loading...':'No Users Found' }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="seconize-dropdown">
                    <label>Assignee</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="selectedAssignee" placeholder="Select Assignee"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of userList" [value]="item.value">{{ item.label }}
                            </mat-option>
                            <mat-option *ngIf="userList.length<=0">
                                {{ isUsersLoading?'Loading...':'No Users Found' }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="seconize-dropdown-container gap-10">
                <div class="seconize-dropdown">
                    <label>Category</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="selectedCategory" placeholder="Select Category"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of categoryList" [value]="item.value">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="seconize-dropdown">
                    <label>Asset Type</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            (selectionChange)="getAssetSubTypes($event.value, filtersForm.get('selectedCategory')?.value)"
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="selectedType" placeholder="Select Asset Type"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of assetTypeList" [value]="item.value">{{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="seconize-dropdown-container gap-10">
                <div class="seconize-dropdown">
                    <label>Asset Sub Type</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="selectedSubType" placeholder="Select Asset Sub Type"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of assetSubTypesList" [value]="item.value">{{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="seconize-dropdown">
                    <label>Is Overdue?</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="selectedOverDue" placeholder="Select"
                            panelClass="seconize-input-dropdown">
                            <mat-option value="true">Yes</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="seconize-dropdown-container gap-10">
                <button mat-button class="seconizeButton fontSizeCustom" [matMenuTriggerFor]="age">
                    {{ selectedAgeRelation }}
                    <mat-icon>expand_more</mat-icon>
                </button>
                <mat-menu #age="matMenu">
                    <button mat-menu-item (click)="ageRelation('=')"> = (Equal To)</button>
                    <button mat-menu-item (click)="ageRelation('>')"> > (Greater Than)</button>
                    <button mat-menu-item (click)="ageRelation('<')">
                      < (Less Than)</button>
                  </mat-menu>
                <div class="seconize-dropdown">
                    <label>Age</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <input type="number" matInput min="0" placeholder="in Days" formControlName="selectedAge" />
                    </mat-form-field>
                </div>

            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="audit-footer-btn">
        <button mat-fab extended class="cancel-btn btn-icon-txt btn" (click)="resetFilter()">
            Reset
        </button>
        <button mat-fab extended class="primary-btn btn-icon-txt btn" (click)="onApply()">
            Apply
        </button>
    </div>
</mat-dialog-actions>