<app-login-wrapper [page]="'forgot-pwd'">
  <div class="col-8 align-self-strech">
    <ng-container
      *ngTemplateOutlet="
        currentStep === 'step1'
          ? step1
          : currentStep === 'step2'
          ? step2
          : currentStep === 'step3'
          ? step3
          : step4
      "
    >
    </ng-container>
  </div>
</app-login-wrapper>

<!-- TODO : do code cleanup -->
<!-- old code -->
<div class="login-wrapper" *ngIf="false">
  <div class="column-shape">
    <img src="../../../assets/images/login/Column.png" alt="" />
  </div>
  <div class="container-fluid">
    <img
      src="../../../assets/images/login/seconize-logo.png"
      alt=""
      class="header-logo"
    />
    <div class="row" style="gap: 40px">
      <div class="col-2 left-side align-self-strech">
        <div class="image-side">
          <img
            src="../../../assets/images/login/login-wraper.png"
            alt=""
            class="responsive-img"
          />
        </div>
        <div class="shape-txt">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </div>
      </div>

      <div class="col-8 login-card align-self-strech">
        <div>
          <div class="card-header">
            <img
              class="img-cls"
              src="../../../assets/images/login/logo-new.png"
              alt=""
            />
          </div>
          <div class="card-body">
            <ng-container *ngIf="currentStep === 'step1'">
              <ng-container *ngTemplateOutlet="step1"> </ng-container>
            </ng-container>
            <ng-container *ngIf="currentStep === 'step2'">
              <ng-container *ngTemplateOutlet="step2"> </ng-container>
            </ng-container>
            <ng-container *ngIf="currentStep === 'step3'">
              <ng-container *ngTemplateOutlet="step3"> </ng-container>
            </ng-container>
            <ng-container *ngIf="currentStep === 'step4'">
              <ng-container *ngTemplateOutlet="step4"> </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="grey-txt copy-txt">
    Powered by <span class="fw-700 black-txt">Seconize</span> v3.0.0
  </p>
</div>

<ng-template #step1>
  <section class="forgot-wrapper">
    <p class="typo">Please enter your User ID to reset the password</p>
    <form [formGroup]="loginForm">
      <div class="flex flex-column gap-2 mb-24px">
        <label>User ID</label>
        <input
          type="text"
          pInputText
          formControlName="email"
          placeholder="Enter user ID"
          maxlength="360"
        />

        <small class="error-text">
          {{ checkError("email") }}
        </small>
      </div>
      <p-button
        label="Reset Password"
        pRipple
        (click)="resetPassword()"
        [disabled]="loginForm.invalid"
      />
    </form>
  </section>
</ng-template>

<ng-template #step2>
  <section class="forgot-wrapper">
    <p class="typo">
      We have sent a reset password email to registered Email ID. Please click
      the reset password link to set your new password.
    </p>
    <p class="link" routerLinkActive="active" style="cursor: pointer;" (click)="navigateToLogin()">Go back to Login</p>
    <p class="typo">Didn't receive the email yet ?</p>
    <p class="typo">
      please check your spam folder, or
      <span (click)="currentStep = 'step1'" class="link" style="cursor: pointer;">try again</span>
    </p>
    <p class="typo">
      If you need further assistance, please contact
      <a class="link" href="mailto:support@seconize.co"
        >support&#64;seconize.co</a
      >
    </p>
  </section>
</ng-template>

<ng-template #step3>
  <section class="forgot-wrapper" style="margin-top: 44px">
    <h2 class="password-heading">Set a new password</h2>
    <p class="typo">
      Create a new password. Ensure it differs from previous ones for security
    </p>
    <form [formGroup]="newPasswordForm">
      <div class="seconize-input-filed">
        <div>
          <label class="login-label">Password</label>
          <!-- <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
          >
            <input
              formControlName="newPassword"
              [type]="passwordVisible ? 'text' : 'password'"
              matInput
              placeholder="Enter your new password"
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="passwordVisible = !passwordVisible"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="!passwordVisible"
            >
              <mat-icon style="color: #e1e1e1">{{
                passwordVisible ? "visibility" : "visibility_off"
              }}</mat-icon>
            </button>
            <mat-error
              *ngIf="
                newPasswordForm.controls['newPassword'] &&
                newPasswordForm.controls['newPassword'].errors &&
                newPasswordForm.controls['newPassword'].errors['required']
              "
            >
              Password is required
            </mat-error>
            <mat-error
              *ngIf="
                newPasswordForm.controls['newPassword'] &&
                newPasswordForm.controls['newPassword'].errors &&
                newPasswordForm.controls['newPassword'].errors['pattern']
              "
            >
              Password does not match the requirements.
            </mat-error>
          </mat-form-field> -->
        </div>
        <div>
          <label class="login-label">Confirm Password</label>
          <!-- <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
          >
            <input
              formControlName="confirmNewPassword"
              [type]="confirmPasswordVisible ? 'text' : 'password'"
              matInput
              placeholder="Re-enter password"
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="confirmPasswordVisible = !confirmPasswordVisible"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="!confirmPasswordVisible"
            >
              <mat-icon style="color: #e1e1e1">{{
                confirmPasswordVisible ? "visibility" : "visibility_off"
              }}</mat-icon>
            </button>
            <mat-error
              style="font-size: 12px"
              *ngIf="
                newPasswordForm.errors && newPasswordForm.errors['mismatch']
              "
            >
              Passwords don’t match.
            </mat-error>
          </mat-form-field> -->
        </div>
      </div>

      <p-button label="Update Password" pRipple (click)="changePassword()" />
      <!-- [disabled]="loginForm.invalid" -->

      <!-- <div class="seconize-btn">
        <button
          mat-flat-button
          class="primary-btn btn"
          style="width: 100%"
          (click)="changePassword()"
        >
          Update Password
        </button>
      </div> -->
    </form>
  </section>
</ng-template>

<ng-template #step4>
  <section class="forgot-wrapper middle-container">
    <h2 class="password-heading">Password Updated Successfully</h2>
    <p class="typo" style="margin-bottom: 16px">
      Redirecting to the login page, please wait a moment.
    </p>
    <p class="typo">
      If the page doesn’t automatically redirect, please
      <span class="link">click here</span>
    </p>
  </section>
</ng-template>
