<mat-dialog-content> 
    <div class="modal-header">
        <h3 class="fw-bold">{{data.title}}</h3>
        <i class="ph ph-x" style="cursor: pointer;" (click)="onClose()"></i>
    </div>
    <form [formGroup]="filtersFrom">
        <div class="seconize-input-filed">
            <div class="seconize-dropdown-container">
                <div class="seconize-dropdown">
                    <label>Asset Type</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="assetType" placeholder="Select Asset Type"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of assetTypeList" [value]="item.name">
                                {{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="seconize-dropdown">
                    <label for="">Asset Name</label>
                    <!-- <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;" class="auto-complete-search">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <input type="text" placeholder="Select one" (focus)="getAssetNameListForDropdown()" aria-label="Asset Name" matInput
                          formControlName="assetName" [matAutocomplete]="autoAssetName" />
                        <mat-autocomplete #autoAssetName="matAutocomplete" [panelWidth]="240" classList="auto-dropdown" class="auto-dropdown input-dropdown">
                          <mat-option class="mat-option-custom" *ngFor="let item of filteredAssetNameOptions | async"
                            [value]="item.name">
                            <span *ngIf="item.name && item.name.length > 20" matTooltip="{{ item.name }}">
                              {{ item.name | slice: 0:20 }}...
                            </span>
                            <span *ngIf="item.name && item.name.length <= 20">{{ item.name }}</span>
                          </mat-option>
                      
                          <mat-option *ngIf="assetsNameList.length <= 0">
                            {{ isAssetNamesLoading ? 'Loading...' : 'No Asset Names Found' }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field> -->
                      
                    <div appearance="outline" style="width: 100%; margin-top: 4px;" class="auto-complete-search">
                        <!-- <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <input type="text" placeholder="Select one" (focus)="getAssetNameListForDropdown()" aria-label="Asset Name" matInput
                          formControlName="assetName" [matAutocomplete]="autoAssetName" /> -->
                        <p-autoComplete styleClass="w-100" formControlName="autoAssetName" placeholder="Select one"   (completeMethod)="search($event)" [suggestions]="suggestions">
                          <!-- <mat-option class="mat-option-custom" *ngFor="let item of filteredAssetNameOptions | async"
                            [value]="item.name">
                            <span *ngIf="item.name && item.name.length > 20" matTooltip="{{ item.name }}">
                              {{ item.name | slice: 0:20 }}...
                            </span>
                            <span *ngIf="item.name && item.name.length <= 20">{{ item.name }}</span>
                          </mat-option>
                      
                          <mat-option *ngIf="assetsNameList.length <= 0">
                            {{ isAssetNamesLoading ? 'Loading...' : 'No Asset Names Found' }}
                          </mat-option> -->
                        </p-autoComplete>
                    </div>
                </div>
            </div>

            <div class="seconize-dropdown-container">
                <div class="seconize-dropdown">
                    <label>Vulnerability</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="vulnerability" placeholder="Select Vulnerability"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of vtDataForFilter" [value]="item.seconizeName">
                                <span *ngIf="item.seconizeName.length > 30" matTooltip="{{ item.seconizeName }}">
                                    {{ item.seconizeName | slice: 0:30 }}...
                                </span>
                                <span *ngIf="item.seconizeName.length <= 30">
                                    {{ item.seconizeName }}
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="seconize-dropdown">
                    <label>State</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="state" placeholder="Select State"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of ruleStates" [value]="item.value">
                                {{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="audit-footer-btn">
        <button mat-fab extended class="cancel-btn btn-icon-txt btn" (click)="resetFilter()">
            Reset
        </button>
        <button mat-fab extended class="primary-btn btn-icon-txt btn" (click)="onApply()">
            Apply
        </button>
    </div>
</mat-dialog-actions>