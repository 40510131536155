<mat-dialog-content>
    <div class="modal-header">
        <h3 class="fw-bold">{{data.title}}</h3>
        <i class="ph ph-x" style="cursor: pointer;" (click)="onClose()"></i>
    </div>
    <form [formGroup]="filtersFrom">
        <div class="seconize-input-filed">
            <div class="seconize-dropdown-container">
                <div class="seconize-dropdown">
                    <label>Remediation</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="remediation" placeholder="Select Remediation"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of remediationList" [value]="item">
                                <span *ngIf="item.length > 25" matTooltip="{{ item }}">{{ item | slice: 0:25 }}...</span>
                                <span *ngIf="item.length <= 25">{{ item }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="seconize-dropdown">
                    <label for="">Vulnerability</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="vulnerability" placeholder="Select Vulnerability"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of vtDataForFilter" [value]="item.seconizeName">
                                <span *ngIf="item.seconizeName.length > 30" matTooltip="{{ item.seconizeName }}">
                                    {{ item.seconizeName | slice: 0:30 }}...
                                </span>
                                <span *ngIf="item.seconizeName.length <= 30">
                                    {{ item.seconizeName }}
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="seconize-dropdown-container">
                <div class="seconize-dropdown">
                    <label>Asset Type</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="assetType" placeholder="Select Asset Type"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of assetTypeList" [value]="item.name">
                                {{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="seconize-dropdown">
                    <label for="">Asset Group</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;" (click)="getAllAssetGroups()">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="assetGroup" placeholder="Select Asset Group"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of assetGroupList" [value]="item">{{ item }}
                            </mat-option>
                            <mat-option *ngIf="assetGroupList.length<=0">
                                {{isAssetGroupsLoading?'Loading...':'No Asset Groups Found'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="seconize-dropdown-container">
                <div class="seconize-dropdown">
                    <label>State</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'"
                            formControlName="state" placeholder="Select State"
                            panelClass="seconize-input-dropdown">
                            <mat-option *ngFor="let item of ruleStates" [value]="item.value">
                                {{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="popup-footer-btn">
        <button mat-fab extended class="cancel-btn btn-icon-txt btn" (click)="resetFilter()">
            Reset
        </button>
        <button mat-fab extended class="primary-btn btn-icon-txt btn" (click)="onApply()">
            Apply
        </button>
    </div>
</mat-dialog-actions>